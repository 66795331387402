import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar.component';
import { 
    NbLayoutModule, 
    NbIconModule, 
    NbButtonModule 
} from '@nebular/theme';
import { NavbarRethinkComponent } from './rethink/navbar-rethink/navbar-rethink.component';



@NgModule({
  declarations: [NavbarComponent, NavbarRethinkComponent],
  imports: [
    CommonModule,
    NbLayoutModule,
    NbIconModule,
    NbButtonModule,
  ],
  exports: [
      NavbarComponent
  ]
})
export class NavbarModule { }
