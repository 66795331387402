// Modifyable Start //

import { Subscriptions } from './Subscriptions';
import { Rethink } from './Rethink';
import { Exercises } from './Exercise';
// TODO: Remove dev
type MasterRequestList_DEFINE =
    { id: "rethink_scrapeHeader", payload: RethinkRequest.ScrapeHeader, response?: RethinkRequest.ScrapeHeaderResponse; } |
    { id: "rethink_getHeader", payload: RethinkRequest.GetHeader, response?: RethinkRequest.GetHeaderResponse; } |
    { id: "rethink_getExerciseByN", payload: RethinkRequest.GetExerciseByN, response?: RethinkRequest.GetExerciseByNResponse; } |
    { id: "rethink_getRandomExercise", payload: RethinkRequest.GetRandomExercise, response?: RethinkRequest.GetRandomExerciseResponse; } |
    { id: "rethink_getPublicExerciseByN", payload: RethinkRequest.GetPublicExerciseByN, response?: RethinkRequest.GetPublicExerciseByNResponse; } |
    { id: "rethink_setSetting", payload: RethinkRequest.SetSetting, response?: RethinkRequest.SetSettingResponse; } |
    { id: "rethink_getSetting", payload: RethinkRequest.GetSetting, response?: RethinkRequest.GetSettingResponse; } |
    { id: "rethink_getSubPackageID", payload: RethinkRequest.GetSubPackageID, response?: RethinkRequest.GetSubPackageIDResponse; } |
    { id: "rethink_getSocialLinks", payload: RethinkRequest.GetSocialLinks, response?: RethinkRequest.GetSocialLinksResponse; } |
    { id: "rethink_getHeaderEnable", payload: RethinkRequest.GetHeaderEnable, response?: RethinkRequest.GetHeaderEnableResponse; } |
    { id: "rethink_generateReport", payload: RethinkRequest.GenerateReport, response?: RethinkRequest.GenerateReportResponse; } |
    { id: "rethink_exerciseFinish", payload: RethinkRequest.ExerciseFinish, response?: RethinkRequest.ExerciseFinishResponse; } |
    { id: "rethink_exerciseStarted", payload: RethinkRequest.ExerciseStarted, response?: RethinkRequest.ExerciseStartedResponse; } |
    { id: "subscriptions_getAll", payload: SubscriptionsRequest.GetAll, response?: SubscriptionsRequest.GetAllResponse; } |
    { id: "subscriptions_getPackage", payload: SubscriptionsRequest.GetPackage, response?: SubscriptionsRequest.GetPackageResponse; } |
    { id: "subscriptions_createPackage", payload: SubscriptionsRequest.CreatePackage, response?: SubscriptionsRequest.CreatePackageResponse; } |
    { id: "subscriptions_updatePackage", payload: SubscriptionsRequest.UpdatePackage, response?: SubscriptionsRequest.UpdatePackageResponse; } |
    { id: "subscriptions_createPlan", payload: SubscriptionsRequest.CreatePlan, response?: SubscriptionsRequest.CreatePlanResponse; } |
    { id: "subscriptions_updatePlan", payload: SubscriptionsRequest.UpdatePlan, response?: SubscriptionsRequest.UpdatePlanResponse; } |
    { id: "subscriptions_deletePackage", payload: SubscriptionsRequest.DeletePackage, response?: SubscriptionsRequest.DeletePackageResponse; } |
    { id: "subscriptions_deletePlan", payload: SubscriptionsRequest.DeletePlan, response?: SubscriptionsRequest.DeletePlanResponse; } |
    { id: "subscriptions_getPaymentInfo", payload: SubscriptionsRequest.GetPaymentInfo, response?: SubscriptionsRequest.GetPaymentInfoResponse; } |
    { id: "subscriptions_authorizeSubscriber", payload: SubscriptionsRequest.AuthorizeSubscriber, response?: SubscriptionsRequest.AuthorizeSubscriberResponse; } |
    { id: "subscriptions_registerSubscription", payload: SubscriptionsRequest.RegisterSubscription, response?: SubscriptionsRequest.RegisterSubscriptionResponse; } |
    { id: "subscriptions_getAuthSubscriberInfo", payload: SubscriptionsRequest.GetAuthSubscriberInfo, response?: SubscriptionsRequest.GetAuthSubscriberInfoResponse; } |
    { id: "subscriptions_cancelAuthSubscription", payload: SubscriptionsRequest.CancelAuthSubscription, response?: SubscriptionsRequest.CancelAuthSubscriptionResponse; } |
    { id: "payment_setAccountInfo", payload: PaymentRequest.SetAccountInfo, response?: PaymentRequest.SetAccountInfoResponse; } |
    { id: "payment_getClientKey", payload: PaymentRequest.GetClientKey, response?: PaymentRequest.GetClientKeyResponse; } |
    { id: "payment_verify", payload: PaymentRequest.Verify, response?: PaymentRequest.VerifyResponse; } |
    { id: "payment_verifyCurrent", payload: PaymentRequest.VerifyCurrent, response?: PaymentRequest.VerifyCurrentResponse; } |
    { id: "payment_deleteAccountInfo", payload: PaymentRequest.DeleteAccountInfo, response?: PaymentRequest.DeleteAccountInfoResponse; } |
    { id: "users_getUsers", payload: UsersRequest.GetUsers, response?: UsersRequest.GetUsersResponse; } |
    { id: "users_getGroupsForUser", payload: UsersRequest.GetGroupsForUser, response?: UsersRequest.GetGroupsForUserResponse; } |
    { id: "users_getGroups", payload: UsersRequest.GetGroups, response?: UsersRequest.GetGroupsResponse; } |
    { id: "users_setUserEmail", payload: UsersRequest.SetUserEmail, response?: UsersRequest.SetUserEmail; } |
    { id: "users_setUserRoles", payload: UsersRequest.SetUserRoles, response?: UsersRequest.SetUserRolesResponse; } |
    { id: "users_setPassword", payload: UsersRequest.SetPassword, response?: UsersRequest.SetPasswordResponse; } |
    { id: "users_deleteUser", payload: UsersRequest.DeleteUser, response?: UsersRequest.DeleteUserResponse; } |
    { id: "users_searchUsers", payload: UsersRequest.SearchUsers, response?: UsersRequest.SearchUsersResponse; } |
    { id: "users_deleteThisUser", payload: UsersRequest.DeleteThisUser, response?: UsersRequest.DeleteThisUser; } |
    { id: "logs_addLog", payload: LogsRequest.AddLog, response?: LogsRequest.AddLogResponse; } |
    { id: "logs_getLogs", payoad: LogsRequest.GetLogs, response?: LogsRequest.GetLogsResponse; };

const RequestPermissions_DEFINE: { [key in Comms.RequestID]: Comms.RequestRequirements } = {
    rethink_scrapeHeader: { requiredRoles: [] },
    rethink_getHeader: { requiredRoles: [] },
    rethink_getExerciseByN: { requiredRoles: [Rethink.SubscriberUserGroupName] },
    rethink_getRandomExercise: { requiredRoles: [Rethink.SubscriberUserGroupName] },
    rethink_getPublicExerciseByN: { requiredRoles: [] },
    rethink_setSetting: { requiredRoles: ['admin'] },
    rethink_getSetting: { requiredRoles: ['admin'] },
    rethink_getSubPackageID: { requiredRoles: [] },
    rethink_getSocialLinks: { requiredRoles: [] },
    rethink_getHeaderEnable: { requiredRoles: [] },
    rethink_generateReport: { requiredRoles: [] },
    rethink_exerciseFinish: { requiredRoles: [], needsIDToken: true },
    rethink_exerciseStarted: { requiredRoles: [], needsIDToken: true },
    subscriptions_getAll: { requiredRoles: ['admin'] },
    subscriptions_getPackage: { requiredRoles: ['admin'] },
    subscriptions_createPackage: { requiredRoles: ['admin'] },
    subscriptions_updatePackage: { requiredRoles: ['admin'] },
    subscriptions_createPlan: { requiredRoles: ['admin'] },
    subscriptions_updatePlan: { requiredRoles: ['admin'] },
    subscriptions_deletePackage: { requiredRoles: ['admin'] },
    subscriptions_deletePlan: { requiredRoles: ['admin'] },
    subscriptions_getPaymentInfo: { requiredRoles: [] },
    subscriptions_registerSubscription: { requiredRoles: [] },
    subscriptions_authorizeSubscriber: { requiredRoles: [] },
    subscriptions_getAuthSubscriberInfo: { requiredRoles: [], needsIDToken: true },
    subscriptions_cancelAuthSubscription: { requiredRoles: [], needsIDToken: true },
    payment_setAccountInfo: { requiredRoles: ['admin'] },
    payment_getClientKey: { requiredRoles: [] },
    payment_verify: { requiredRoles: ['admin'] },
    payment_verifyCurrent: { requiredRoles: ['admin'] },
    payment_deleteAccountInfo: { requiredRoles: ['admin'] },
    users_getUsers: { requiredRoles: ['admin'] },
    users_getGroupsForUser: { requiredRoles: ['admin'] },
    users_getGroups: { requiredRoles: ['admin'] },
    users_setUserEmail: { requiredRoles: ['admin'] },
    users_setUserRoles: { requiredRoles: ['admin'] },
    users_setPassword: { requiredRoles: ['admin'] },
    users_deleteUser: { requiredRoles: ['admin'] },
    users_searchUsers: { requiredRoles: ['admin'] },
    users_deleteThisUser: { requiredRoles: [], needsIDToken: true },
    logs_addLog: { requiredRoles: ['admin'] },
    logs_getLogs: { requiredRoles: ['admin'] }
};


// Modifyable End  //


export namespace Comms {
    export type Request = MasterRequestList_DEFINE & {
        auth?: {
            idToken?: string,
            accessToken?: string,
        };
    };
    export type RequestID = typeof SampleRequest.id;
    export type RequestRequirements = {
        requiredRoles: string[],
        needsIDToken?: boolean,
        needsAccessToken?: boolean,
    };
    export const RequestPermissions = RequestPermissions_DEFINE;

    export type Response<R extends Request> = {
        payload: R['response'],
        error?: any;
    } | any;
    export type Success = typeof SuccessResponse;
    export const SuccessResponse = "Success";
}

export namespace LogsRequest {
    export type Log = {
        id: string,
        name: string,
        status: "success" | "danger" | "warning",
        timeMS: number,
        details: string;
    };

    export type AddLog = Omit<Log, "id">;
    export type AddLogResponse = Comms.Success;

    export type GetLogs = {
        n?: number,
        startKey?: string,
    };
    export type GetLogsResponse = {
        logs: Log[],
        lastkey: string;
    };
}


export namespace CompositeDB {

    export type Structure = {
        app_config: { payments: boolean, subscriptions: boolean; },

        rethink_header: { headerSerialized: string; }; // JSON string of Rethink.Header
    };

    export type ID = keyof Structure;

}

export namespace UsersRequest {

    export type UsersSortType = "Create_Date";

    export type GetUsers = {
        sortBy?: UsersSortType,
        n?: number,
        paginationToken?;
    };
    export type GetUsersResponse = {
        PaginationToken?: string,
        Users: AWSCognitoUser[];
    };
    export type AWSCognitoUser = {
        Enabled: boolean,
        UserCreateDate: string,
        UserLastModifiedDate: string,
        UserStatus: string,
        Attributes: AWSCognitoUserAttribute[];
    };
    export type AWSCognitoUserAttribute = {
        Name: AWSCognitoUserAttributeName,
        Value: string;
    };
    export type AWSCognitoUserAttributeName =
        "sub" |
        "custom:occupation" |
        "email_verified" |
        "custom:wins" |
        "custom:name" |
        "custom:jobtitle" |
        "custom:losses" |
        "email" |
        "custom:company";

    export type AWSGroup = {
        GroupName: string;
        UserPoolId: string;
        RoleArn: string;
        Precedence: number;
        LastModifiedDate: string;
        CreationDate: string;
    };




    export type GetGroupsForUser = {
        id: string;
    };
    export type GetGroupsForUserResponse = AWSGroup[];

    export type GetGroups = {};
    export type GetGroupsResponse = AWSGroup[];

    export type SetPassword = {
        userID: string,
        newPassowrd: string;
    };
    export type SetPasswordResponse = Comms.Success;

    export type SetUserEmail = {
        userID: string,
        email: string;
    };
    export type SetUserEmailResponse = Comms.Success;

    export type SetUserRoles = {
        userID: string,
        roles: string[];
    };
    export type SetUserRolesResponse = Comms.Success;

    export type DeleteUser = {
        userID: string;
    };
    export type DeleteUserResponse = Comms.Success;

    export type SearchUsers = {
        emailPrefix: string;
    };
    export type SearchUsersResponse = GetUsersResponse;

    export type DeleteThisUser = {};
    export type DeleteThisUserResponse = Comms.Success;

}

export namespace SubscriptionsRequest {

    export type GetAll = {};
    export type GetAllResponse = {
        packages: Subscriptions.PackageModel[];
    };

    export type GetPackage = {
        packageID: string;
    };
    export type GetPackageResponse = Subscriptions.PackageModel;

    export type CreatePackage = Subscriptions.PackageModel;
    export type CreatePackageResponse = Comms.Success;

    export type UpdatePackage = Subscriptions.PackageModel;
    export type UpdatePackageResponse = Comms.Success;

    export type CreatePlan = UpdatePlan;
    export type CreatePlanResponse = Comms.Success;

    export type UpdatePlan = {
        plan: Subscriptions.PlanModel,
        packageID: string;
    };
    export type UpdatePlanResponse = Comms.Success;

    export type DeletePackage = {
        packageID: string;
    };
    export type DeletePackageResponse = Comms.Success;

    export type DeletePlan = {
        planID: string,
        packageID: string;
    };
    export type DeletePlanResponse = Comms.Success;

    export type GetPaymentInfo = {
        packageID: string;
    };
    export type GetPaymentInfoResponse = {
        package: Subscriptions.PackageModel,
        clientID: string;
    };

    export type RegisterSubscriber = {
        userID: string,
        subscriptionID: string,
        packageID: string;
    };
    export type RegisterSubscriberResponse = Comms.Success;

    export type VerifySubscription = {
        subscriptionID: string;
    };
    export type VerifySubscriptionResponse = {
        success?: boolean,
        authorizedGroups: string[],
        errorType?: "INVALID_STATUS" | "OTHER",
        status?: string,
        message?: string;
    };

    export type VerifySubscriber = {
        userID: string;
    };
    export type VerifySubscriberResponse = {
        subscriptionID: string,
        planID: string;
    };

    export type GetUserSubID = {
        userID: string;
    };
    export type GetUserSubIDResponse = string[];

    export type RegisterSubscription = {
        subscriptionID: string,
        packageID;
    };
    export type RegisterSubscriptionResponse = Comms.Success;

    export type AuthorizeSubscriber = {
        userID: string;
    };
    export type AuthorizeSubscriberResponse = Comms.Success;

    export type GetAuthSubscriberInfo = {};
    export type GetAuthSubscriberInfoResponse = {
        subscriptions: (Subscriptions.PaypalSubscription & { details?: any; })[],
        packages: Subscriptions.PackageModel[],
        errors: any[];
    };

    export type CancelAuthSubscription = {
        planID: string;
    };
    export type CancelAuthSubscriptionResponse = Comms.Success;
}

export namespace PaymentRequest {
    export type SetAccountInfo = {
        clientID: string,
        secret: string;
    };
    export type SetAccountInfoResponse = Comms.Success;

    export type GetClientKey = {};
    export type GetClientKeyResponse = string;

    export type Verify = {
        clientID: string,
        secret: string;
    };
    export type VerifyResponse = Comms.Success | "Payment Keys Invalid";

    export type VerifyCurrent = {};
    export type VerifyCurrentResponse = VerifyResponse;

    export type DeleteAccountInfo = {};
    export type DeleteAccountInfoResponse = Comms.Success;
}


export namespace RethinkRequest {
    export type ScrapeHeader = {};
    export type ScrapeHeaderResponse = Rethink.Header;

    export type GetHeader = {};
    export type GetHeaderResponse = Rethink.Header;

    export type GetExerciseByN = { n: number; };
    export type GetExerciseByNResponse = Exercises.Exercise;

    export type GetRandomExercise = {};
    export type GetRandomExerciseResponse = Exercises.Exercise;

    export type GetPublicExerciseByN = { n: number; };
    export type GetPublicExerciseByNResponse = Exercises.Exercise;

    export type SetSetting = { id: string, value: string; };
    export type SetSettingResponse = Comms.Success;

    export type GetSetting = { id: string; };
    export type GetSettingResponse = string;

    export type GetSubPackageID = {};
    export type GetSubPackageIDResponse = string;

    export type GetSocialLinks = {};
    export type GetSocialLinksResponse = {
        twitter: string,
        linkedin: string;
    };

    export type GetHeaderEnable = {};
    export type GetHeaderEnableResponse = {
        isEnable: boolean;
    };

    export type GenerateReport = {};
    export type GenerateReportResponse = Comms.Success;

    export type ExerciseFinish = {
        correct: number
        exerciseN: number
        userAnswers: number[]
    };
    export type ExerciseFinishResponse = Comms.Success;

    export type ExerciseStarted = {
        exerciseN: number
    }
    export type ExerciseStartedResponse = Comms.Success;
}



const SampleRequest = <Comms.Request>{};
