import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import * as AWS from "aws-sdk";
import * as JWTDecode from 'jwt-decode';
import { AuthTypes } from 'src-shared/Auth';
import { UTIL } from 'src-shared/Utilities';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    async signup(user: AuthTypes.SignUpUser) {
        let newUser = await Auth.signUp({
            password: user.password,
            username: user.email,
            attributes: {
                email: user.email,
                'custom:name': user.name,
                'custom:company': user.company,
                'custom:jobtitle': user.jobtitle,
                'custom:occupation': user.occupation
            }
        });
        return newUser;
    }

    async signin(user: AuthTypes.SignInUser) {
        let signInResult = await Auth.signIn({
            username: user.email,
            password: user.password
        });
        return signInResult;
    }

    async signout() {
        await Auth.signOut();
    }

    async isSignedIn(): Promise<boolean> {
        try {
            let current = await Auth.currentAuthenticatedUser();
            return true;
        } catch (err) {
            return false;
        }
    }

    async getUserGroups(): Promise<string[]> {
        if (!this.isSignedIn()) {
            return [];
        } else {
            try {
                let groups = (await this.getAccessDecoded())['cognito:groups'];
                if (typeof groups == 'object') {
                    return groups;
                } else {
                    return [];
                }
            } catch (e) {
                return [];
            }
        }
    }

    async isAdmin() {
        return (await this.getUserGroups()).includes('admin');
    }

    async getAccessDecoded(): Promise<any> {
        let token = await (await Auth.currentSession()).getAccessToken().getJwtToken();
        let decoded = JWTDecode(token) as any;
        return decoded;
    }

    async getUserDetails(): Promise<AuthTypes.UserDetails> {
        let attr = (await Auth.currentUserInfo()).attributes;
        return {
            email: attr.email,
            name: attr['custom:name'],
            company: attr['custom:company'],
            title: attr['custom:jobtitle'],
            occupation: attr['custom:occupation'],
            wins: attr['custom:wins'],
            losses: attr['custom:losses']
        };
    }

    async setUserDetails(a: AuthTypes.UserDetails) {
        let usr = (await Auth.currentAuthenticatedUser());
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(a.email)) { throw 'Invalid email address'; }
        let attr = {
            'email': a.email,
            'custom:name': a.name,
            'custom:company': a.company,
            'custom:jobtitle': a.title,
            'custom:occupation': a.occupation,
            'custom:wins': a.wins,
            'custom:losses': a.losses
        };
        for (let x of Object.keys(attr)) {
            if (!UTIL.doesExist(attr[x])) { delete attr[x]; }
        }
        let r = (await Auth.updateUserAttributes(usr, attr));
        return r;
    }

    async sendResetPW(email: string) {
        return await Auth.forgotPassword(email);
    }
    async changePW(email: string, pw: string, code: string) {
        return await Auth.forgotPasswordSubmit(email, code, pw);
    }
    async sendEmailVerify() {
        return await Auth.verifyCurrentUserAttribute('email');
    }
    async submitEmailVerify(code: string) {
        return await Auth.verifyCurrentUserAttributeSubmit('email', code);
    }
    async isEmailVerified(): Promise<boolean> {
        let r = (await Auth.currentUserInfo()).attributes.email_verified;
        if (r == true) { return true; } else { return false; }
    }
    async getUserID(): Promise<string> {
        return (await Auth.currentUserInfo()).username;
    }
    async refreshToken() {
        let usr = await (await Auth.currentAuthenticatedUser({bypassCache:true}));
        let currentSession = await Auth.currentSession();
        return usr.refreshSession(usr.signInUserSession.refreshToken, ()=>{
            
        });
    }


    constructor() { }

}


