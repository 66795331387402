import { Component, OnInit } from '@angular/core';
import { CommsService } from 'src/comms/comms.service';
import { Redirect } from 'src/Redirect';

@Component({
    selector: 'app-deactivateplanwarn',
    templateUrl: './deactivateplanwarn.component.html',
    styleUrls: ['./deactivateplanwarn.component.scss']
})
export class DeactivateplanwarnComponent implements OnInit {

    actions = [
        {
            id: 'deactivate',
            text: 'Deactivate',
            status: 'danger'
        },
        {
            id: 'close',
            text: 'Close',
            status: 'basic',
            isCloseButton: true
        }
    ];

    loading = false;
    pname = '';
    isPackage = false;
    packageID;
    planID;

    async actionClick(id) {
        if (id == 'deactivate') {
            this.loading = true;
            let res;
            if (this.isPackage) {
                res = await this.comms.request({
                    id: "subscriptions_deletePackage",
                    payload: {
                        packageID: this.packageID
                    }
                });
            } else {
                res = await this.comms.request({
                    id: "subscriptions_deletePlan",
                    payload: {
                        packageID: this.packageID,
                        planID: this.planID
                    }
                });
            }

            
            if (res.payload == 'Success') {
                Redirect.reload();
            }
        }
    }

    constructor(private comms: CommsService) { }

    ngOnInit(): void {
    }

}
