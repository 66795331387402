import { Subscriptions } from "./Subscriptions";
export namespace Samples {
    export namespace SampleSubscriptions {
        export const Plan: Subscriptions.PlanModel = {
            accessRole: "planaR",
            billPeriodDurationInDays: 30,
            billPrice: 4.55,
            currencyCode: "USD",
            name: "dummy plan",
            onUnsubscribe: "immediate_unenroll",
            billingPaused: true,
            enrollmentPaused: true,
            staticID: "SampleStaticID",
            trialPeriodDurationInDays: 14
        };
        export const Package: Subscriptions.PackageModel = {
            accessRole: "testAR",
            name: "Dummy Name",
            ccEnrollmentPolicy: "disallow_cancel",
            dateCreated: 1596515786226,
            staticID: "dummystaticID",
            plans: [Plan]
        }
    }
}
