import { Injectable } from '@angular/core';
import { API, Auth } from 'aws-amplify';
import { Comms, SubscriptionsRequest } from 'src-shared/CommsRequests';
import { UTIL } from 'src-shared/Utilities';

@Injectable({
    providedIn: 'root'
})
export class CommsService {

    async request<R extends Comms.Request>(request: Comms.Request) : Promise<Comms.Response<R>> {
        let r = <Comms.Request>JSON.parse(JSON.stringify(request));
        r.auth = {};
        try {
            if (Comms.RequestPermissions[request.id].needsIDToken) {
                r.auth.idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
            }
            if (Comms.RequestPermissions[request.id].needsAccessToken || Comms.RequestPermissions[request.id].requiredRoles.length > 0) {
                r.auth.accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
            }
        } catch(e) {
            throw 'Comms failed: Auth tokens unavailable';
        }
        
        let response = this.send(r);
        return response as any;
    }

    private async send(a:Comms.Request) : Promise<any> {
        let initObject = {
            body: a,
        };
        let startTime = Date.now();
        let response = await API.post("compositeep", "/request/client", initObject);
        let endTime = Date.now();
        
        return response;
    }

    constructor() { }
}
