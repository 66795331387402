import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Subscriptions } from 'src-shared/Subscriptions';
import { Samples } from 'src-shared/SampleData';
import { UTIL } from 'src-shared/Utilities';
import { CommsService } from 'src/comms/comms.service';
import { Comms } from 'src-shared/CommsRequests';
import { Redirect } from 'src/Redirect';
import { ConsolepopupService } from 'src/app/console/popup/consolepopup.service';

@Component({
    selector: 'app-subscriptions-plan',
    templateUrl: './subscriptions-plan.component.html',
    styleUrls: ['./subscriptions-plan.component.scss']
})
export class SubscriptionsPlanComponent implements OnInit, OnChanges {

    sub = Subscriptions;
    
    billPrice = "";
    annualTotal = "";
    billFrequency = "";
    statusName = "";
    statusColor = "";


    @Input() plan : Subscriptions.PlanModel;
    @Input() package : Subscriptions.PackageModel;

    actionClick(x) {
        this.cp.openPlanAction({
            plan: this.plan,
            packageID: this.package.staticID,
            pname: this.plan.name,
            action: x
        });
    }


    update() {
        this.billPrice = (this.sub.CurrencyList[this.plan.currencyCode].sign) + UTIL.numberWithCommas(this.plan.billPrice.toFixed(2));
        let annualTotal = this.plan.billPrice * (365 / this.plan.billPeriodDurationInDays);
        this.annualTotal = (this.sub.CurrencyList[this.plan.currencyCode].sign) + UTIL.numberWithCommas(annualTotal.toFixed(2));

        if (Object.keys(this.sub.DefaultBillingPeriods).includes(this.plan.billPeriodDurationInDays.toString())) {
            this.billFrequency = this.sub.DefaultBillingPeriods[this.plan.billPeriodDurationInDays];
        } else {
            this.billFrequency = this.plan.billPeriodDurationInDays + " Days";
        }

        let statusid = Subscriptions.getPlanStatusForAttributes({
            billingPaused: this.plan.billingPaused,
            enrollmentPaused: this.plan.enrollmentPaused
        });

        this.statusName = Subscriptions.SubscriptionPlanStatusList[statusid].description;
        this.statusColor = Subscriptions.SubscriptionPlanStatusList[statusid].color;
    }

    async deletePlanClick() {
        this.cp.openDeactivatePlanWarning({
            packageID: this.package.staticID,
            planID: this.plan.staticID,
            pname: this.plan.name
        });
    }


    constructor(private comms: CommsService, private cp: ConsolepopupService) { }

    ngOnInit(): void {
        this.update();
    }

    ngOnChanges(changes) {
        this.update();
    }

}
