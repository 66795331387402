import { Component, OnInit } from '@angular/core';
import { Redirect } from 'src/Redirect';

@Component({
    selector: 'app-apps',
    templateUrl: './apps.component.html',
    styleUrls: ['./apps.component.scss']
})
export class AppsComponent implements OnInit {

    featureList: {
        name: string,
        icon: string,
        id: string,
        state: boolean,
        disabled?: boolean
    }[] = [
        {
            name: "Users & Authentication",
            icon: "people-outline",
            id: "users",
            state: true,
            disabled: true,
        },
        {
            name: "System Logs",
            icon: "file-text-outline",
            id: "logs",
            state: true,
            disabled: true,
        },
        {
            name: "Payments",
            icon: "credit-card-outline",
            id: "payments",
            state: true
        },
        {
            name: "Subscriptions",
            icon: "shopping-cart-outline",
            id: "subscriptions",
            state: true
        },
    ]

    constructor() { }

    click(feature) {
        localStorage['FEATURESHOW_'+feature.id] = JSON.stringify(feature.state);
        Redirect.reload();
    }

    ngOnInit(): void {
        for (let f of this.featureList) {
            if (localStorage['FEATURESHOW_'+f.id] == 'true') {
                f.state = true;
            }
            if (localStorage['FEATURESHOW_'+f.id] == 'false') {
                f.state = false;
            }
        }
    }

}