import { Component, OnInit } from '@angular/core';
import { Rethink } from 'src-shared/Rethink';
import { CommsService } from 'src/comms/comms.service';
import { CachedService } from 'src/comms/cached.service';
import { RethinkRequest, Comms } from 'src-shared/CommsRequests';

@Component({
    selector: 'app-navbar-rethink',
    templateUrl: './navbar-rethink.component.html',
    styleUrls: ['./navbar-rethink.component.scss']
})
export class NavbarRethinkComponent implements OnInit {

    twitterlink = "";
    linkedinlink = "";
    header: Rethink.Header = {
        items: []
    };
    metaheaderEnable = true;
    isMobile = false;
    goto(link) {
        if (link == "#") { return; }
        window.location.href = link;
    }
    twitterClick() {
        this.goto(this.twitterlink);
    }
    linkedinClick() {
        this.goto(this.linkedinlink);
    }

    async fetchSocial() {
        let res = await this.cached.request({
            id: "rethink_getSocialLinks",
            payload: {}
        }, this.setupSocial.bind(this));
        this.setupSocial(res);
    }
    setupSocial(response) {
        if (!response.error) {
            let p: RethinkRequest.GetSocialLinksResponse = response.payload;
            this.twitterlink = p.twitter;
            this.linkedinlink = p.linkedin;
        }
    }

    async fetchHeader() {
        let response = await this.cached.request({
            id: "rethink_getHeader",
            payload: {}
        }, this.setupHeader.bind(this));
        this.setupHeader(response);
    }

    setupHeader(response) {
        if (!response.error) {
            let parser = new DOMParser();
            for (let i of (response.payload as Rethink.Header).items) {
                i.title = this.decodeHtml(i.title);
                for (let subI of i.subMenus) {
                    subI.title = this.decodeHtml(subI.title);
                }
            }
            this.header = response.payload as any;
        } else {
            
        }
    }

    decodeHtml(html) {
        let txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    async checkEnable() {
        let r = await this.cached.request({
            id: "rethink_getHeaderEnable",
            payload: {}
        }, this.setupEnable.bind(this));
        this.setupEnable(r);
    }
    setupEnable(response) {
        if (!response.error) {
            this.metaheaderEnable = response.payload.isEnable == "true";
        }
    }



    onResize(event) {
        if (event.target.innerWidth < 1200) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }
    }

    constructor(private comms: CommsService, private cached: CachedService) { }

    ngOnInit(): void {
        this.onResize({
            target: window
        });
        this.fetchHeader();
        this.fetchSocial();
        this.checkEnable();
    }

}
