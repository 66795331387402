<h1>Logs</h1>
<div class="contentDiv">
    <nb-accordion>
        <nb-accordion-item *ngFor="let l of logs">
            <nb-accordion-item-header>
                <div class="headerItem">
                    <div [className]="'dot ' + l.status"></div>
                    {{l.name}}
                </div>
                <div class="headerItemRight" style="float: right;">
                    <nb-icon icon="clock-outline"></nb-icon>
                    {{getDate(l.timeMS)}}
                </div>
            </nb-accordion-item-header>
            <nb-accordion-item-body>
                <p>{{l.details}}</p>
                <div><b>ID: </b>{{l.id}}</div>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
    <br/>
    <br/>
    <button nbButton outline status="primary" style="margin: auto; display: block;" [nbSpinner]='loading' nbSpinnerStatus="primary" (click)="loadClick()">Load More</button>
    <br/>
    <br/>
</div>