import { Component, OnInit } from '@angular/core';
import { actionModel } from '../popup-frame/popup-frame.component';
import { CommsService } from 'src/comms/comms.service';
import { Redirect } from 'src/Redirect';
import { UserRow } from '../../../pages/users/users.component';
import { UTIL } from 'src-shared/Utilities';
import { UsersRequest } from 'src-shared/CommsRequests';

@Component({
    selector: 'app-user-actions-popup',
    templateUrl: './user-actions-popup.component.html',
    styleUrls: ['./user-actions-popup.component.scss']
})
export class UserActionsPopupComponent implements OnInit {

    actions : actionModel[] = [
        {
            id: 'delete',
            text: 'Delete User',
            status: 'danger'
        },
        {
            id: 'close',
            text: 'Cancel',
            status: 'basic',
            isCloseButton: true
        }
    ]
    user: UserRow;
    currentPage: 'actions' | 'resetpw' | 'changeemail' | 'editroles' | 'delete' = 'actions';
    loading = false;
    roles: string[];
    setRoles: {[s:string]:boolean} = {};
    // TODO: prevent interference between admin set roles and subscription roles
    newPW: string;
    pwError = "";

    newEmail: string;
    emailError = "";

    async actionClick(id) {
        if (id == 'delete') {
            this.loading = true;
            let res = await this.comms.request({
                id: "users_deleteUser",
                payload: {
                    userID: this.user.id
                }
            });
            if (res.payload == "Success") {
                Redirect.reload();
            }
            this.loading = false;
        }
        if (id == "setroles") {
            let toSet = [];
            for (let sR of this.roles) {
                if (this.setRoles[sR] == true) {
                    toSet.push(sR);
                }
            }
            this.loading = true;
            let req = await this.comms.request({
                id: "users_setUserRoles",
                payload: {
                    roles: toSet,
                    userID: this.user.id
                }
            });
            if (req.payload == "Success") {
                Redirect.reload();
            }
        }
        if (id == "setpw") {
            this.loading = true;
            try {
                let req = await this.comms.request({
                    id: "users_setPassword",
                    payload: {
                        newPassowrd: this.newPW,
                        userID: this.user.id
                    }
                });
                if (req.error) {
                    if (req.error.message) {
                        this.pwError = req.error.message;
                    } else {
                        this.pwError = JSON.stringify(req.error);
                    }
                    this.loading = false;
                    return;
                }
                Redirect.reload();
            } catch (e) {
                if (e.message) {
                    this.pwError = e.message;
                } else {
                    this.pwError = JSON.stringify(e);
                }
                this.loading = false;
            }
            
        }
        if (id == "setemail") {
            this.loading = true;
            try {
                let req = await this.comms.request({
                    id: "users_setUserEmail",
                    payload: {
                        email: this.newEmail,
                        userID: this.user.id
                    }
                });
                if (req.error) { throw req.error; }
                Redirect.reload();
            } catch(e) {
                if (e.message) {
                    this.emailError = e.message;
                } else {
                    this.emailError = JSON.stringify(e);
                }
                this.loading = false;
            }
        }
    }

    setPage(p) {
        this.currentPage = p;
        switch (this.currentPage) {
            case "editroles": this.editPageSet(); break;
            case "resetpw": this.setPwPageSet(); break;
            case "changeemail": this.changeEmailPageSet(); break;
        }
    }
    changeEmailPageSet() {
        this.actions = [
            {
                id: 'setemail',
                text: 'Set Email',
                status: 'primary'
            },
            {
                id: 'close',
                text: 'Cancel',
                status: 'basic',
                isCloseButton: true
            }
        ]
    }
    editPageSet() {
        this.actions = [
            {
                id: 'setroles',
                text: 'Set Roles',
                status: 'primary'
            },
            {
                id: 'close',
                text: 'Cancel',
                status: 'basic',
                isCloseButton: true
            }
        ]
    }
    setPwPageSet() {
        this.actions = [
            {
                id: 'setpw',
                text: 'Set Password',
                status: 'primary'
            },
            {
                id: 'close',
                text: 'Cancel',
                status: 'basic',
                isCloseButton: true
            }
        ]
    }

    getAttr(a: UsersRequest.AWSCognitoUserAttributeName) {
        let match = UTIL.getFirstKeyValueMatch(this.user.user.Attributes, "Name", a);
        if (!match) { return ''; }
        if (a == 'custom:occupation') { return JSON.parse(UTIL.getFirstKeyValueMatch(this.user.user.Attributes, "Name", a).Value); }
        return UTIL.getFirstKeyValueMatch(this.user.user.Attributes, "Name", a).Value;
    }

    async getRoles() {
        let res = await this.comms.request({
            id: "users_getGroups",
            payload: {}
        });
        if (res.payload) {
            this.roles = [];
            for (let ag of res.payload) {
                let n = (ag as UsersRequest.AWSGroup).GroupName;
                this.roles.push(n);
                this.setRoles[n] = false;
            }
            for (let cg of this.user.roles) {
                this.setRoles[cg.name] = true;
            }
        }
    }

    async configure() {
        this.getRoles();
    }
    constructor(private comms: CommsService) { }

    ngOnInit(): void {
        this.configure();
    }

}
