import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConsoleComponent } from './console/console.component';
import { AboutComponent } from './console/pages/about/about.component';
import { AppsComponent } from './console/pages/apps/apps.component';
import { SubscriptionsComponent } from './console/pages/subscriptions/subscriptions.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ForgotpasswordComponent } from './auth/forgotpassword/forgotpassword.component';
import { SigninComponent } from './auth/signin/signin.component';
import { UsersComponent } from './console/pages/users/users.component';
import { LogsComponent } from './console/pages/logs/logs.component';
import { PaymentsComponent } from './console/pages/payments/payments.component';
import { Redirect } from 'src/Redirect';
import { VerifyemailComponent } from './auth/verifyemail/verifyemail.component';
import { SettingsComponent } from './console/pages/settings/settings.component';

const routes: Routes = [
    { path: Redirect.DEF.SignIn, component: SigninComponent },
    // { path: Redirect.DEF.SignUp, component: SignupComponent },
    { path: Redirect.DEF.ForgotPassword, component: ForgotpasswordComponent },
    { path: Redirect.DEF.VerifyEmail, component: VerifyemailComponent },
    {
        path: Redirect.DEF.AdminConsole,
        component: ConsoleComponent,
        children: [
            { path: "about", component: AboutComponent },
            { path: "apps", component: AppsComponent },
            { path: "users", component: UsersComponent },
            { path: "logs", component: LogsComponent },
            { path: "payments", component: PaymentsComponent },
            { path: "subscriptions", component: SubscriptionsComponent },
            { path: "settings", component: SettingsComponent },
            { path: '', redirectTo: '/admin/console/about', pathMatch: 'full' },
        ]
    },
    { path: '', loadChildren: () => import('./site/site.module').then(m => m.SiteModule) }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }

