import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Redirect } from '../../Redirect';
import { AuthService } from 'src/auth/auth.service';
import { UTIL } from 'src-shared/Utilities';
import { Rethink } from 'src-shared/Rethink';
import { CommsService } from 'src/comms/comms.service';
import { NbThemeService } from '@nebular/theme';
import { RethinkCustomService } from 'src/rethink/rethink-custom';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    @Input() customHeader: boolean = false;
    @Input() menuStatus: boolean = false;
    @Input() header: Rethink.Header = {
        items: []
    };

    @Output() menuToggle = new EventEmitter<boolean>();
    isLightTheme = false;
    isSignedIn = false;
    isOutsideConsole = false;
    isAllowedInConsole = false;
    isProfileActionsToggled = false;
    isSubscribed = false;
    winGlobal = window.global as any;
    
    currentUrl: string;
    isLmsExerciseUrl: boolean = false;

    user = {
        name: "",
        email: ""
    };
    showBelowHeaderButtons = false;

    menuClick() {
        this.menuStatus = !this.menuStatus;
        this.menuToggle.emit(this.menuStatus);
    }
    signInClick() {
        Redirect.toSignInWithRebound(window.location.href);
    }
    createAccountClick() {
        Redirect.to(Redirect.DEF.Subscribe);
    }
    profileClick() {
        this.isProfileActionsToggled = !this.isProfileActionsToggled;
    }
    profileClickOut(event) {
        if (event.relatedTarget) {
            if (event.relatedTarget.classList) {
                if (!event.relatedTarget.classList.includes('preventFocusOut')) {
                    return;
                }
            }
        }
        this.isProfileActionsToggled = false;
    }
    consoleToggleClick() {
        if (this.isOutsideConsole) {
            Redirect.to(Redirect.DEF.AdminConsole);
        } else {
            Redirect.to(Redirect.DEF.Home);
        }
    }
    profileActionClick(action: "myaccount" | "signout" | "managesub") {
        switch (action) {
            case "signout": this.signout(); break;
            case "myaccount": Redirect.to(Redirect.DEF.Account); break;
            case "managesub": Redirect.to(Redirect.DEF.ManageSubscriptions); break;
            default: UTIL.assertUnreachable(action);
        }
    }
    subscribeClick() {
        Redirect.to(Redirect.DEF.Subscribe);
    }
    async signout() {
        try {
            await this.authService.signout();
            Redirect.to(Redirect.DEF.DidLogout);
        } catch (err) {
            // TODO: Show error
            if (err.message) {
                console.log(err.message);
            }
            console.log(err);
        }
    }
    headerActionClick(link) {
        window.location.href = link;
    }
    async fetchHeader() {
        let response = await this.comms.request({
            id: "rethink_getHeader",
            payload: {}
        });
        if (!response.error) {
            let parser = new DOMParser();
            for (let i of (response.payload as Rethink.Header).items) {
                i.title = i.title.replace(/\&amp;/gm, "&");
                for (let subI of i.subMenus) {
                    subI.title = subI.title.replace(/\&amp;/gm, "&");
                }
            }
            this.header = response.payload as any;
            this.header.items = this.header.items.reverse();
        } else {
            // TODO: Handle
        }
    }
    async checkAuth() {
        this.isSignedIn = await this.authService.isSignedIn();
        this.configureNavbar();
    }



    async configureNavbar() {
        this.isAllowedInConsole = await this.authService.isAdmin();
        this.isOutsideConsole = !Redirect.checkIfAt(Redirect.DEF.AdminConsole);
        this.showBelowHeaderButtons = true;
        for (let p of [Redirect.DEF.SignIn, Redirect.DEF.SignUp, Redirect.DEF.ForgotPassword, Redirect.DEF.Payment, Redirect.DEF.Subscribe]) {
            if (Redirect.checkIfAt(p)) {
                this.showBelowHeaderButtons = false;
            }
        }
        if (this.isSignedIn) {
            let usr = await this.authService.getUserDetails();
            this.user = {
                email: usr.email,
                name: usr.name
            };
        }
    }
    async checkIfSubscribed() {
        this.isSubscribed = (await this.rethink.IsValidSubscriber());
    }

    constructor(private authService: AuthService, private comms: CommsService, private themeService: NbThemeService, private rethink: RethinkCustomService) {
        this.themeService.onThemeChange().subscribe((theme: any) => {
            if (theme.name.includes('light')) {
                this.isLightTheme = true;
            } else {
                this.isLightTheme = false;
            }
            if (theme.name.includes('wp')) {
                this.customHeader = true;
            } else {
                this.customHeader = false;
            }
        });
    }
    ngOnInit(): void {
        this.checkAuth();
        this.checkIfSubscribed();
        
        let url = window.location.href.split('/');
        this.currentUrl = url[url.length - 1].split('?')[0];
        
        console.log('URL',this.currentUrl);
        
        if(this.currentUrl.includes('exercise') || this.currentUrl.includes('lms-exercise')){
            this.isLmsExerciseUrl = true
        }
    }

}
