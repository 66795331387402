import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Rethink } from '../../src-shared/Rethink';
import { UTIL } from 'src-shared/Utilities';
import { Exercises } from 'src-shared/Exercise';
import { Storage } from 'aws-amplify';
import { CommsService } from 'src/comms/comms.service';
import { Comms } from 'src-shared/CommsRequests';

// Custom For This Client

@Injectable({
    providedIn: 'root'
})
export class RethinkCustomService {

    async IsValidSubscriber(): Promise<boolean> {
        try {
            let groups = await this.authService.getUserGroups();
            return (groups.includes(Rethink.SubscriberUserGroupName));
        } catch(e) {
            console.log(e);
            return false;
        }
        
    }

    CompleteExerciseFetch(n?: number, requestPublic?:boolean): Rethink.CompleteExercise {
        // Get Sync Exercise Number (instant)
        let exercise = this.GetLimitedExercise(n);

        if (requestPublic && !exercise.publicVideoRef) { throw 'No public video ref found'; }
        // Set up fetches before waiting
        let promiseFinal: Rethink.PromisedCompleteExercise = {
            isSignedIn: this.authService.isSignedIn(),
            isSubscribed: this.IsValidSubscriber(),
            videoRef: requestPublic ? this.GetPublicVideoRef(n) : this.GetVideoRef(exercise.n),
            exercise: this.GetExercise(exercise.n, requestPublic)
        };

        // Ship Default
        let final: Rethink.CompleteExercise = {
            isSignedIn: false,
            isSubscribed: false,
            videoRef: "",
            exercise: undefined
        };

        // Dispatch All at once
        for (let k of Object.keys(promiseFinal)) {
            (promiseFinal[k].then((v) => { final[k] = v; }));
        }

        return final;
    }

    GetLimitedExercise(n?: number): Exercises.LimitedExercise {

        let qb = Exercises.LimitedQuestionBank;
        if (UTIL.doesExist(n)) {
            for (let x of qb) {
                if (x.n == n) {
                    return x;
                }
            }
            throw 'Invalid exercise number';
        }
        let newN = this.GetNewExerciseN();
        return qb[newN];
    }

    GetNewExerciseN() {
        let possible = []; 
        let keys = Object.keys(Exercises.LimitedQuestionBank);
        let used: string[] = localStorage['USED_EXERCISE_N'];
        if (!used) { used = []; } else {
            used = JSON.parse(used as any);
        }
        for (let kN in keys) {
            let k = Exercises.LimitedQuestionBank[kN].n.toString();
            if (!used.includes(k)) {
                possible.push(kN);
            }
        }
        if (possible.length == 0) {
            possible = keys
            localStorage['USED_EXERCISE_N'] = '[]';
        }
        return possible[parseInt((Math.random() * possible.length).toString())];
    }

    async GetExercise(n?: number, requestPublic?:boolean) {
        let res: Comms.Response<Comms.Request>;
        if (UTIL.doesExist(n)) {
            if (requestPublic) {
                res = await this.comms.request({ id: "rethink_getPublicExerciseByN", payload: { n: n } });
            } else {
                res = await this.comms.request({ id: "rethink_getExerciseByN", payload: { n: n } });
            }
        } else {
            res = await this.comms.request({ id: "rethink_getRandomExercise", payload: {} });
        }
        if (res.error) { throw JSON.stringify(res.error); }
        return res.payload as Exercises.Exercise;
    }

    async GetVideoRef(n?: number): Promise<string> {
        let key = this.GetLimitedExercise(n).s3Key;
        return Storage.get(key) as Promise<string>;
    }

    async GetPublicVideoRef(n: number): Promise<string> {
        return this.GetLimitedExercise(n).publicVideoRef;
    }

    

    constructor(private authService: AuthService, private comms: CommsService) { }
}