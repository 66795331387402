import { Component, OnInit } from '@angular/core';
import { CommsService } from 'src/comms/comms.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    settings: setting[] = [

        {
            setting: "text",
            id: "subPackageID",
            name: "Subscription Package Static ID",
            description: "Set this to the Static ID of the Subscription package which contains the plans you want to offer on the subscribe page.",
            value: ""
        },
        {
            setting: "text",
            id: "headerTwitterLink",
            name: "Header Twitter Link",
            description: "The link users are redirected to if they click on the Twitter icon in the header.",
            value: ""
        },
        {
            setting: "text",
            id: "headerLinkedinLink",
            name: "Header Linkedin Link",
            description: "The link users are redirected to if they click on the Linkedin icon in the header.",
            value: ""
        },
        {
            setting: "options",
            id: "headerAutomaticMetadataRefresh",
            name: "Header Automatic Metadata Refresh",
            description: "Select if the server should automatically fetch changes in the header metadata from therethinkgroup.net or if you want to manually initiate refresh.",
            options: [
                {
                    id: "auto",
                    name: "Automatically Refresh Metadata"
                },
                {
                    id: "manual",
                    name: "Manually Initiate Refresh"
                }
            ],
            value: ""
        },
        {
            setting: "options",
            id: "metaheaderEnable",
            name: "Enable or Disable the Custom Metadata Header",
            description: "Disabling this option turns off the synchronized metadata based header and removes all dropdown options. The header will automatically revert to this state if there are issues fetching metadata from therethinkgroup.net",
            options: [
                {
                    id: "true",
                    name: "Enable"
                },
                {
                    id: "false",
                    name: "Disable"
                }
            ],
            value: ""
        },
        {
            setting: "action",
            id: "refreshMetadata",
            name: "Refresh Metadata",
            description: "Perform this action to initiate a refresh of the header metadata from therethinkgroup.net",
            value: ""
        },
        {
            setting: "text",
            id: "reportEmailAddress",
            name: "Report Email Address",
            description: "Set the email address you want to send the generated report file to.",
            value: "",
        },
        {
            setting: "action",
            id: "generateReport",
            name: "Generate Report",
            description: "Initiate the report generation process. You may exit the browser after starting, and the report will take a maximum of 15 minutes to reach your inbox.",
            value: ""
        },
        {
            setting: "text",
            id: "adminRoleOverrides",
            name: "Admin Role Overrides",
            description: "This JSON object defines roles which the CMS system cannot remove from certain users. These overrides take precedence over any other CMS subsystem, including Subscriptions, and are created when you modify user roles in the Users tab. Warning: modifying raw JSON text may lead to adverse effects.",
            value: ""
        },
        {
            setting: "action",
            id: "clearAdminRoleOverrides",
            name: "Clear Admin Role Overrides",
            description: "Performing this action will safely reset admin role overrides. No roles will be modified, but other CMS systems will now gain the authority to remove and add roles that an administrator may have previously explicitly awarded.\n\n (For instance, if a user without any subscriptions was previously given a role by an administrator to allow access to paid content, clearing Admin Overrides will allow the Subscriptions sub-system to deauthorize this user in the future.)",
            value: ""
        }

    ];

    async saveBtn(s: setting) {
        s.isLoading = true;
        let res = await this.comms.request({
            id: "rethink_setSetting",
            payload: {
                id: s.id,
                value: s.value
            }
        });
        s.isLoading = false;
    }

    async actionClick(action: setting) {
        if (action.setting == 'action') {

            switch (action.id) {
                case "refreshMetadata":
                    action.isLoading = true;
                    await this.comms.request({
                        id: "rethink_scrapeHeader",
                        payload: {}
                    });
                    action.isLoading = false;
                    break;
                case "generateReport":
                    action.isLoading = true;
                    let r = await this.comms.request({
                        id: "rethink_generateReport",
                        payload: {}
                    });
                    if (r.payload == "Success") {
                        action.isLoading = false;
                    }
                    break;

                case "clearAdminRoleOverrides":
                    action.isLoading = true;
                    for (let s of this.settings) {
                        if (s.id == "adminRoleOverrides") {
                            s.value = "{}";
                            await this.saveBtn(s);
                        }
                    }
                    action.isLoading = false;
                    break;
            }

        }
    }

    async configure() {
        for (let set of this.settings) {
            let res = await this.comms.request({
                id: "rethink_getSetting",
                payload: {
                    id: set.id
                }
            });
            if (!res.error) {
                set.value = res.payload;
            }
        }

    }

    constructor(private comms: CommsService) { }

    ngOnInit(): void {
        this.configure();
    }

}

export type setting = (optionsSetting | checkboxesSetting | textInputSetting | actionSetting) & {
    id: string,
    name: string,
    description: string,
    value: string;
    isLoading?: boolean;
};
export type optionsSetting = {
    setting: "options",
    options: {
        id: string,
        name: string;
    }[];
};
export type checkboxesSetting = {
    setting: "checkboxes",
    boxes: {
        id: string,
        name: string;
    }[];
};
export type textInputSetting = {
    setting: "text";
};
export type actionSetting = {
    setting: "action";
};
