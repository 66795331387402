import { Component, OnInit } from '@angular/core';
import { CommsService } from 'src/comms/comms.service';
import { NbThemeService } from '@nebular/theme';
import { RethinkCustomService } from '../rethink/rethink-custom';
import { API, Storage } from 'aws-amplify';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    title = 'dlzp-serverless-subscriptions';


    setTheme(theme) {
        this.themeService.changeTheme(theme);
    }

    ngOnInit(): void {
    }

    constructor(private _comms: CommsService, private themeService: NbThemeService, private rethinkService: RethinkCustomService) {
    }
}
