import { Component, OnInit } from '@angular/core';
import { CommsService } from 'src/comms/comms.service';
import { ConsolepopupService } from '../../popup/consolepopup.service';


@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

    defaultID = '-';
    defaultKey = '------------THIS_IS_A_PLACEHOLDER_KEY----------------';
    clientID = '';
    secret = '';

    isLoading = false;
    verifyLoading = true;
    verifyPass = false;
    verifyFail = false;

    clientMatches(): boolean { return (this.defaultID == this.clientID); }
    secretMatches(): boolean { return (this.defaultKey == this.secret); }
    credsMatch(): boolean { return (this.clientMatches() && this.secretMatches()); }

    verifyClick() {
        this.checkCurrentVerify();
    }
    deleteClick() {
        this.popup.openPayment_deleteCredentials({});
    }
    uploadClick() {
        this.popup.openPayment_uploadCredentials({
            newID: this.clientID,
            newSecret: this.secret
        });
    }
    async getClientKey() {
        this.isLoading = true;
        let clientRes = (await this.comms.request({ id: "payment_getClientKey", payload: {} }));
        if (clientRes.error) { // TODO: handle 
            throw 'There was an error fetching your client key.';
        }
        this.defaultID = JSON.parse(JSON.stringify(clientRes.payload as string));
        this.clientID = JSON.parse(JSON.stringify(this.defaultID));
        this.secret = JSON.parse(JSON.stringify(this.defaultKey));
        this.isLoading = false;
    }
    async checkCurrentVerify() {
        this.verifyLoading = true;
        this.verifyPass = false;
        this.verifyFail = false;
        let res = (await this.comms.request({ id: "payment_verifyCurrent", payload: {} }));
        if (res.payload != "Success") {
            this.verifyLoading = false;
            this.verifyPass = false;
            this.verifyFail = true;
            return;
        }
        this.verifyLoading = false;
        this.verifyPass = true;
        this.verifyFail = false;
        return;
    }
    async configure() {
        try {
            await this.getClientKey();
        } catch(e) {
            this.defaultKey = '';
            this.isLoading = false;
        }
        
        this.checkCurrentVerify();
    }

    constructor(private comms: CommsService, private popup: ConsolepopupService) { }

    ngOnInit(): void {
        this.configure();
    }

}
