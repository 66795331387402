<app-popup-frame [header]="headtitle" [status]="statuscolor" [actions]="actions" (action)="actionClick($event)" [loading]="loading">
    <div *ngIf="errtext != ''"><b style="color: red;">{{errtext}}</b></div>
    <div class="main" *ngIf="action == 'penroll'">
        <br/>
        Pausing plan enrollment will remove this plan as an option during checkout, however enrolled users will not be unenrolled.
        <br/><br/>
        <b>Are you sure you want to pause enrollment?</b>
    </div>
    <div class="main" *ngIf="action == 'renroll'">
        <br/>
        Resuming enrollment will add this plan as an option during checkout and new users will be able to enroll.
        <br/><br/>
        <b>Are you sure you want to resume enrollment?</b>
    </div>
    <div class="main" *ngIf="action == 'pbilling'">
        <br/>
        Pausing plan billing will prevent all enrolled users from being billing for future billing dates and enrollment will automatically be paused.<br/>
        <br/>
        <b>Warning: This process may take significant time.</b>
        <br/><br/>
        <b>Are you sure you want to pause billing?</b>
    </div>
    <div class="main" *ngIf="action == 'rbilling'">
        <br/>
        Resuming plan billing will charge enrolled users on their future scheduled billing dates.
        <br/><br/>
        <b>Are you sure you want to resume billing?</b>
    </div>
</app-popup-frame>