import { Component, OnInit, Input } from '@angular/core';
import { Samples } from 'src-shared/SampleData';
import { Subscriptions } from 'src-shared/Subscriptions';
import { UTIL } from 'src-shared/Utilities';
import { SubscriptionsRequest, Comms } from 'src-shared/CommsRequests';
import { CommsService } from 'src/comms/comms.service';
import { Redirect } from 'src/Redirect';
import { ConsolepopupService } from 'src/app/console/popup/consolepopup.service';

@Component({
    selector: 'app-subscriptions-package',
    templateUrl: './subscriptions-package.component.html',
    styleUrls: ['./subscriptions-package.component.scss']
})
export class SubscriptionsPackageComponent implements OnInit {

    showPlanEditor = false;
    editingPlan : Subscriptions.PlanModel;
    editorIsLoading = false;
    editorStatus : Subscriptions.SubscriptionPlanStatusID;
    @Input() package : Subscriptions.PackageModel;

    subRef = Subscriptions;
    totalValue = 0;

    resetEditor() {
        let id = UTIL.makeId();
        this.totalValue = 0;
        this.editorStatus = "ready_for_sale"
        this.editingPlan = {
            accessRole: "subscription-plan-" + id,
            billPeriodDurationInDays: 7,
            billPrice: 0,
            billingPaused: false,
            currencyCode: "USD",
            enrollmentPaused: false,
            name: "",
            onUnsubscribe: "immediate_unenroll",
            staticID: id,
            trialPeriodDurationInDays: 0
        }
        this.totalEdit();
    }
    addPlanClick() {
        this.resetEditor();
        this.showPlanEditor = true;
    }
    cancelPlanClick() {
        this.showPlanEditor = false;
    }
    async deletePackageClick() {
        this.cp.openDeactivatePlanWarning({
            isPackage: true,
            packageID: this.package.staticID,
            pname: this.package.name
        });
    }
    async deployPlanClick() {
        // Pre deploy adjust
        // convert strings to nums
        this.editingPlan.billPrice = parseFloat(this.editingPlan.billPrice.toString());
        this.editingPlan.billPeriodDurationInDays = parseInt(this.editingPlan.billPeriodDurationInDays.toString());
        this.editingPlan.trialPeriodDurationInDays = parseInt(this.editingPlan.trialPeriodDurationInDays.toString());
        // Set status
        let attributes = Subscriptions.getAttributesForPlanStatus(this.editorStatus);
        this.editingPlan.billingPaused = attributes.billingPaused;
        this.editingPlan.enrollmentPaused = attributes.enrollmentPaused;

        console.log(this.editingPlan);
        // TODO: Validate inputs and report
        
        this.editorIsLoading = true;
        let response = await this.comms.request({
            id: "subscriptions_createPlan",
            payload: {
                packageID: this.package.staticID,
                plan: this.editingPlan
            }
        });
        if (response.payload != Comms.SuccessResponse) {
            // TODO: Handle Errors
            console.log(response);
        } else {
            this.editorIsLoading = false;
            this.resetEditor();
            this.showPlanEditor = false;
            Redirect.reload();
        }
    }

    getKeys(a) {
        return Object.keys(a);
    }
    parseInt(s) {
        return parseInt(s);
    }
    billPriceEdit() {
        this.editingPlan.billPeriodDurationInDays = parseInt(this.editingPlan.billPeriodDurationInDays.toString());
        this.editingPlan.billPrice = (parseFloat(this.editingPlan.billPrice.toString()).toFixed(2)) as any;
        this.totalValue = this.editingPlan.billPrice * (365 / this.editingPlan.billPeriodDurationInDays);
        this.totalValue = (parseFloat(this.totalValue.toString()).toFixed(2)) as any;
    }
    totalEdit() {
        this.editingPlan.billPeriodDurationInDays = parseInt(this.editingPlan.billPeriodDurationInDays.toString());
        this.totalValue = (parseFloat(this.totalValue.toString()).toFixed(2)) as any;
        this.editingPlan.billPrice = this.totalValue / (365 / this.editingPlan.billPeriodDurationInDays);
        this.editingPlan.billPrice = (parseFloat(this.editingPlan.billPrice.toString()).toFixed(2)) as any;
    }

    constructor(private comms : CommsService, private cp : ConsolepopupService) { }

    ngOnInit(): void {
        this.package.plans = this.package.plans.sort(function(a,b) { return a.billPeriodDurationInDays - b.billPeriodDurationInDays; })
    }

}
