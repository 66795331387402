<nb-card class="planCard" *ngIf="plan">
    <div class="planCardHeader">
        <div class="planCardHeaderTitle" style="width: calc(100% - 300px);">
            <div class="detailName">PLAN NAME</div>
            {{plan.name}}
        </div>
        <div class="planCardHeaderItem" style="width: 100px;">
            <div class="detailName">ANNUAL TOTAL</div>
            {{annualTotal}}
        </div>
        <div class="planCardHeaderItem" style="width: 100px;">
            <div class="detailName">BILL PRICE</div>
            {{billPrice}}
        </div>
        <div class="planCardHeaderItem" style="width: 100px;">
            <div class="detailName">BILL FREQUENCY</div>
           {{billFrequency}}
        </div>
    </div>

    <div class="bodyItems">
        <div class="bodyDetailItem" style="width: 120px;">
            <div class="detailName">STATUS</div>
            <div class="statusDiv">
                <span class="dot" [ngStyle]="{'background-color': statusColor}"></span>
                {{statusName}}
            </div>
        </div>
        <div class="bodyDetailItem" style="margin-left: 100px;">
            <div class="detailName">ON UNSUBSCRIBE</div>
            <div class="statusDiv" style="width: 300px;">
                {{sub.OnUnsubscribePolicyList[plan.onUnsubscribe].description}}
            </div>
        </div>
    </div>


    <div class="bottomActionRow">
        <div class="actionButtons">
            <div class="detailName">ACTIONS</div>
            <button nbButton outline status="warning" *ngIf="!plan.enrollmentPaused" (click)="actionClick('penroll')">
                <nb-icon icon="pause-circle-outline"></nb-icon>PAUSE ENROLLMENT
            </button>
            <button nbButton outline status="success" *ngIf="plan.enrollmentPaused" (click)="actionClick('renroll')">
                <nb-icon icon="arrow-right"></nb-icon>RESUME ENROLLMENT
            </button>
            <button nbButton outline status="danger" *ngIf="!plan.billingPaused" (click)="actionClick('pbilling')">
                <nb-icon icon="pause-circle-outline"></nb-icon>PAUSE BILLING
            </button>
            <button nbButton outline status="success" *ngIf="plan.billingPaused" (click)="actionClick('rbilling')">
                <nb-icon icon="arrow-right"></nb-icon>RESUME BILLING
            </button>
        </div>
        <div class="bodyDetailItem" style="margin-left: 40px; margin-top: 0;" *ngIf="false">
            <div class="detailName">ACCESS ROLE</div>
            <div class="statusDiv" style="font-size: 12px; width: 140px;">
                <i>{{plan.accessRole}}</i>
                <button ghost nbButton style="min-width: 30px; height: 30px; padding: 0px;">
                    <nb-icon icon="edit-outline" status="primary" style="padding: 2px;"></nb-icon>
                </button>
            </div>
        </div>
        <div class="bodyDetailItemStatic" style="margin-left: 40px; margin-top: 0;">
            <div class="detailName">STATIC ID</div>
            <div class="statusDiv" style="font-size: 12px; width: 190px; overflow-wrap: break-word;">
                <i>{{plan.staticID}}</i>
            </div>
        </div>
        <div class="dButton">
            <button nbButton status="danger" (click)="deletePlanClick()">DEACTIVATE</button>
        </div>
    </div>
</nb-card>
