import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionsPlanComponent } from './consolepage/subscriptions-plan/subscriptions-plan.component';
import { NbIconModule, NbCardModule, NbButtonModule, NbTabsetModule, NbSpinnerModule, NbRadioModule, NbInputModule, NbSelectModule } from '@nebular/theme';
import { SubscriptionsConsolepageComponent } from './consolepage/subscriptions-consolepage/subscriptions-consolepage.component';
import { SubscriptionsPackageComponent } from './consolepage/subscriptions-package/subscriptions-package.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [SubscriptionsPlanComponent, SubscriptionsConsolepageComponent, SubscriptionsPackageComponent],
  imports: [
    CommonModule,
    FormsModule,

    NbIconModule,
    NbCardModule,
    NbButtonModule,
    NbTabsetModule,
    NbSpinnerModule,
    NbRadioModule,
    NbInputModule,
    NbSelectModule,
  ],
  exports: [
    SubscriptionsPlanComponent,
    SubscriptionsConsolepageComponent
  ]
})
export class SubscriptionsModule { }
