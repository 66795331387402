<nb-layout>
    <nb-layout-header>
        <app-navbar></app-navbar>
    </nb-layout-header>
    <nb-layout-column>
        <div class="innerContent">
            <h1>
                <b>Intuition Brain Game:</b> {{isVerify ? 'Verify Email' : 'Reset Password'}}
            </h1>
            <h2 *ngIf="isCodeAvail()">
                Verification email sent to <b style="font-size:19px;">{{email}}</b>. Please check your inbox.
            </h2>
        </div>
        <div class="main" *ngIf="!success && !isVerify">
            <button nbButton ghost status="primary" class="goHomeButton" (click)="goHomeClick()">
                <nb-icon icon="arrow-back-outline"></nb-icon>Back To Home
            </button>


            <nb-card [nbSpinner]="loading" nbSpinnerStatus="primary" *ngIf="!isCodeAvail()">
                <nb-card-body>
                    <div class="errorBar" *ngIf="invalid">
                        <nb-icon icon="alert-circle-outline"></nb-icon>{{invalidMessage}}
                        <br style="clear: both" />
                    </div>
                    <b>Email</b>
                    <input nbInput fullWidth type="email" autocomplete="email" [(ngModel)]="email">
                    <br /><br />
                    <button nbButton status="primary" class="submit" fullWidth (click)="sendLinkClick()">Send Recovery Code</button>
                </nb-card-body>
            </nb-card>

            <nb-card [nbSpinner]="loading" nbSpinnerStatus="primary" *ngIf="isCodeAvail()">
                <nb-card-body>
                    <div class="errorBar" *ngIf="invalid">
                        <nb-icon icon="alert-circle-outline"></nb-icon>{{invalidMessage}}
                        <br style="clear: both" />
                    </div>
                    <b>Email</b>
                    <input nbInput fullWidth type="email" autocomplete="email" [(ngModel)]="verifyEmail">
                    <br /><br />
                    <b>New Password</b>
                    <input nbInput fullWidth type="password" autocomplete="new-password" [(ngModel)]="newPW">
                    <b>Confirm Password</b>
                    <input nbInput fullWidth type="password" autocomplete="new-password" [(ngModel)]="newPWConfirm">
                    <br style="clear: both;"/><br/>
                    <b style="text-align: center;">Verification Code</b>
                    <input nbInput class="codeClass" [(ngModel)]="params.code">
                    <br />
                    <button nbButton status="primary" class="submit" fullWidth (click)="resetPWClick()">Update Password</button>
                </nb-card-body>
            </nb-card>
        </div>
        <div class="main" *ngIf="!success && isVerify">

            <button nbButton ghost status="primary" class="goHomeButton" (click)="goHomeClick()">
                <nb-icon icon="arrow-back-outline"></nb-icon>Back To Home
            </button>


            <nb-card [nbSpinner]="loading" nbSpinnerStatus="primary" *ngIf="!isCodeAvail() && isLoggedIn">
                <nb-card-body>
                    <div class="errorBar" *ngIf="invalid">
                        <nb-icon icon="alert-circle-outline"></nb-icon>{{invalidMessage}}
                        <br style="clear: both" />
                    </div>
                    <b>Email</b>
                    <input nbInput fullWidth type="email" autocomplete="email" disabled [(ngModel)]="email" style="background-color: transparent; border-color: rgba(255,255,255,0.2); color: rgba(255,255,255,0.8);">
                    <br /><br />
                    <button nbButton status="primary" class="submit" fullWidth (click)="sendVerify()">Send Verification Code</button>
                </nb-card-body>
            </nb-card>

            <nb-card [nbSpinner]="loading" nbSpinnerStatus="primary" *ngIf="isCodeAvail() && isLoggedIn">
                <nb-card-body>
                    <div class="errorBar" *ngIf="invalid">
                        <nb-icon icon="alert-circle-outline"></nb-icon>{{invalidMessage}}
                        <br style="clear: both" />
                    </div>
                    <b style="text-align: center;">Verification Code</b>
                    <input nbInput class="codeClass" [(ngModel)]="params.code">
                    <br />
                    <button nbButton status="primary" class="submit" fullWidth (click)="verifyClick()">Verify Email</button>
                    <br style="clear: both;"/><br/>
                    <button nbButton status="primary" class="submit" fullWidth outline (click)="sendVerify()">Resend Code</button>
                </nb-card-body>
            </nb-card>

            <nb-card *ngIf="!isLoggedIn">
                <nb-card-body>
                    <div style="text-align: center;">
                        <b style="text-align: center;">You need to log in to verify your email.</b>
                    </div>
                </nb-card-body>
                
            </nb-card>
            <button nbButton status="primary" outline *ngIf="!isLoggedIn" shape="round" style=" background-color: black; width: 150px; display: block; margin: auto;" (click)="loginVerifyClick()">Log In</button>
        </div>
        <div class="main success" *ngIf="success">
            <nb-card>
                <nb-card-body>
                    <div>
                        <b *ngIf="!isVerify">Password successfully updated.</b>
                        <b *ngIf="isVerify">Email verified.</b>
                    </div>
                    <br/>
                    <nb-icon icon="checkmark"></nb-icon>
                </nb-card-body>
            </nb-card>
            <button nbButton status="primary" outline shape="round" *ngIf="!isVerify" style="background-color: black; width: 150px; display: block; margin: auto;" (click)="loginClick()">Log In</button>
            <button nbButton status="primary" outline shape="round" *ngIf="isVerify" style="width: 150px; display: block; margin: auto;" (click)="goHomeClick()">Back To Home</button>
        </div>
    </nb-layout-column>
</nb-layout>