import { Component, OnInit } from '@angular/core';
import { actionModel } from '../popup-frame/popup-frame.component';
import { CommsService } from 'src/comms/comms.service';
import { Redirect } from 'src/Redirect';

@Component({
    selector: 'app-delete-payment-creds-popup',
    templateUrl: './delete-payment-creds-popup.component.html',
    styleUrls: ['./delete-payment-creds-popup.component.scss']
})
export class DeletePaymentCredsPopupComponent implements OnInit {

    actions : actionModel[] = [
        {
            id: 'delete',
            text: 'Delete',
            status: 'danger'
        },
        {
            id: 'close',
            text: 'Close',
            status: 'basic',
            isCloseButton: true
        }
    ]

    loading = false;

    async actionClick(id) {
        if (id == 'delete') {
            this.loading = true;
            let res = await this.commms.request({
                id: "payment_deleteAccountInfo",
                payload: {}
            });
            if (res.payload == "Success") {
                Redirect.reload();
            }
            this.loading = false;
        }
    }

    constructor(private commms: CommsService) { }

    ngOnInit(): void {
    }

}
