// Globally Available Routing Constants
export namespace Redirect {
    export enum DEF {
        Home = "",
        SignIn = "login",
        SignUp = "subscribe",
        ForgotPassword = "forgotpassword",
        VerifyEmail = "verify",
        AdminConsole = "admin/console",
        DoLogout =  "?doLogout=true",
        DidLogout = "?isAfterLogout=true",
        Practice = "practice",
        Subscribe = "subscribe",
        Payment = "payment",
        Exercise = "exercise",
        LmsExercise = "lms-exercise",
        LmsExerciseId = "lms-exercise/:id",
        ManageSubscriptions = "manage-subscription",
        Account = "account",
        Privacy = "assets/documents/Privacy Policy - ReThink.pdf",
        TraderIntuitionPDF = "assets/documents/Nature of Market Intuition 2020 .pdf",
        About = "about",
        ReThinkSite = "https://therethinkgroup.net/"
    }
    export function to(loc: DEF, newTab?:boolean) {
        if (newTab) {
            window.open(loc, '_blank');
            return;
        }
        window.location.href = loc;
    }

    export function toSignInWithRebound(url: string) {
        window.location.href = DEF.SignIn + '?rebound=' + url;
    }

    export function reload() {
        window.location.reload();
    }

    export function checkIfAt(location:DEF | string) : boolean {
        return window.location.pathname.startsWith('/'+location);
    }
    
}