import { UTIL } from './Utilities';

// Modifyable Start //
const CurrencyList_DEFINE = {
    USD: {name: "US Dollar", sign: "$"}
}

const ConcurrentEnrollmentPolicyList_DEFINE = {
    allow: {description: "Allow users to enroll in multiple plans concurrently."},
    warn: {description: "Warn users if they are about to enroll in multiple plans concurrently."},
    disallow_cancel: {description: "Disallow concurrent enrollment and automatically cancel previous plan."}
}

const OnUnsubscribePolicyList_DEFINE = {
    immediate_unenroll: {description: "Immediately unenroll and revoke access."},
    delayed: {description: "Delay unenroll until next billing period."}
}

const SubscriptionPlanStatusList_DEFINE = {
    ready_for_sale: {description: "Ready for Sale", color: "#00d68f", detailedDescription: "Users can enroll in this plan and billing is active."},
    enrollment_paused: {description: "Enrollment Paused", color: "#ffc94d", detailedDescription: "New users can't enroll in this plan, but users who are already enrolled will continue to be billed."},
    billing_paused: {description: "Billing Paused", color: "#ff3d71", detailedDescription: "Enrolled users are not billed, and new users can still sign up. Careful, this is dangerous!"},
    enrollment_and_billing_paused: {description: "Enrollment and Billing Paused", color: "#ff3d71", detailedDescription: "New users can't enroll in this plan, and users who are already enrolled will not be billed."},
}
const DefaultBillingPeriods_DEFINE: typeof Subscriptions.DefaultBillingPeriods = {
    1: "Daily",
    7: "Weekly",
    14: "Bi-Weekly",
    30: "Monthly",
    89: "Quarterly",
    365: "Annually"
}
const DefaultBillingIntervalName_DEFINE = {
    1: "Day",
    7: 'Week',
    30: "Month",
    89: "Quarter",
    365: "Year"
}
// Modifyable End //


export namespace Subscriptions {
    export interface PackageModel {
        staticID:string,
        name:string,
        plans:PlanModel[],
        ccEnrollmentPolicy:ConcurrentEnrollmentPolicyID, //id of concurrent enrollment policy
        accessRole:string,
        dateCreated:number, // milliseconds since epoch in UTC
        deactivated?:boolean
    }

    // Slightly different type stored in DB, plans array is stringified to keep data flat
    export type PackageModel_DB = Omit<PackageModel, 'plans'> & {plansSerialized:string};
    
    
    export interface PlanModel extends SubscriptionPlanStatusAttributes {
        staticID:string,
        accessRole:string,
        currencyCode:CurrencyCode,
        onUnsubscribe:OnUnsubscribePolicyID,
        name:string,
        billPeriodDurationInDays:number,
        billPrice:number,
        trialPeriodDurationInDays:number,
        deactivated?:boolean
    }
    
    
    export type Currency = {
        name: string,
        sign: string,
    }
    export const CurrencyList: { [key in keyof typeof CurrencyList_DEFINE]:Currency } = CurrencyList_DEFINE;
    export type CurrencyCode = keyof typeof CurrencyList;
    
    
    export type ConcurrentEnrollmentPolicy = {
        description: string
    }
    export const ConcurrentEnrollmentPolicyList: { [key in keyof typeof ConcurrentEnrollmentPolicyList_DEFINE]:ConcurrentEnrollmentPolicy } = ConcurrentEnrollmentPolicyList_DEFINE;
    export type ConcurrentEnrollmentPolicyID = keyof typeof ConcurrentEnrollmentPolicyList;
    
    
    export type OnUnsubscribePolicy = {
        description: string
    }
    export const OnUnsubscribePolicyList: { [key in keyof typeof OnUnsubscribePolicyList_DEFINE]:OnUnsubscribePolicy } = OnUnsubscribePolicyList_DEFINE;
    export type OnUnsubscribePolicyID = keyof typeof OnUnsubscribePolicyList;
    
    
    export const DefaultBillingPeriods: { [period: number]:string } = DefaultBillingPeriods_DEFINE;
    export const DefaultBillingIntervalName = DefaultBillingIntervalName_DEFINE;
    

    export type SubscriptionPlanStatus = {
        description: string,
        color: string,
        detailedDescription: string,
    }
    export const SubscriptionPlanStatusList: { [key in keyof typeof SubscriptionPlanStatusList_DEFINE]: SubscriptionPlanStatus } = SubscriptionPlanStatusList_DEFINE;
    export type SubscriptionPlanStatusID = keyof typeof SubscriptionPlanStatusList;


    export type SubscriptionPlanStatusAttributes = {
        billingPaused: boolean,
        enrollmentPaused: boolean,
    }

    export type PaypalSubscription = {
        subID:string,
        userID:string,
        lastStatus:string,
        planID:string,
        packageID:string
    }

    export function getPlanStatusForAttributes(attributes: SubscriptionPlanStatusAttributes): SubscriptionPlanStatusID {
        switch(attributes.billingPaused) {
            case true: switch(attributes.enrollmentPaused) {
                case true: return "enrollment_and_billing_paused";
                case false: return "billing_paused";
            }
            case false: switch(attributes.enrollmentPaused) {
                case true: return "enrollment_paused";
                case false: return "ready_for_sale";
            }
            default: UTIL.assertUnreachable(attributes.billingPaused);
        }
    }
    export function getAttributesForPlanStatus(id: SubscriptionPlanStatusID): SubscriptionPlanStatusAttributes {
        switch(id) {
            case "ready_for_sale": return { billingPaused: false, enrollmentPaused: false }
            case "billing_paused": return { billingPaused: true, enrollmentPaused: false }
            case "enrollment_paused": return { billingPaused: false, enrollmentPaused: true }
            case "enrollment_and_billing_paused": return { billingPaused: true, enrollmentPaused: true }
            default: UTIL.assertUnreachable(id);
        }
    }

    export function convertDBtoNormal(p: Subscriptions.PackageModel_DB): Subscriptions.PackageModel {
        let r = {
            ...p,
            plans: JSON.parse(p.plansSerialized)
        }
        delete r.plansSerialized;
        return r;
    }
    export function convertNormalToDB(p: Subscriptions.PackageModel): Subscriptions.PackageModel_DB {
        let r  = {
            ...p,
            plansSerialized: JSON.stringify(p.plans)
        }
        delete r.plans;
        return r;
    }
    
}