import { Component, OnInit } from '@angular/core';
import { Samples } from 'src-shared/SampleData'

@Component({
    selector: 'app-subscriptions',
    templateUrl: './subscriptions.component.html',
    styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {
    
    demoPlan = Samples.SampleSubscriptions.Plan; 

    constructor() { }

    ngOnInit(): void {
    }

}
