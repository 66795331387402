<ng-container *ngIf="currentUrl != 'lms-exercise'">

    <div class="headerContainer" *ngIf="!isOutsideConsole">
        <div class="headerLeft">
            <div class="headerItem logo" *ngIf="isOutsideConsole">
                <img [src]='(isLightTheme) ? "/assets/images/rethink-logo-cl.png" : "/assets/images/rethink-logo-bw.png"'>
            </div>
            <div class="headerItem menu" *ngIf="!isOutsideConsole">
                <button nbButton ghost (click)="menuClick()">
                    <nb-icon icon="menu-outline"></nb-icon>
                </button>
            </div>
            <div class="headerItem title" *ngIf="!isOutsideConsole">
                <div class="text">DLZP CMS</div>
            </div>
        </div>
         <!--Signed In Header -->
        <div class="headerRight" *ngIf="isSignedIn">
            <div class="headerItem profile">
                <button nbButton (click)="profileClick()" (focusout)="profileClickOut($event)">
                    <nb-icon icon="person"></nb-icon>
                </button>
                <div class="profileActions" *ngIf="isProfileActionsToggled">
                    <div class="profileAction">
                        <br style="clear: both;"/>
                        <button nbButton fullWidth status="basic" class="profileActionButton preventFocusOut" outline (click)="profileActionClick('myaccount')" (focusout)="profileClickOut($event)">My Account</button>
                    </div>
                    <div class="profileAction">
                        <br style="clear: both;"/>
                        <button nbButton fullWidth status="danger" class="profileActionButton preventFocusOut" outline (click)="profileActionClick('signout')" (focusout)="profileClickOut($event)">Sign Out</button>
                    </div>
    
                </div>
            </div>
            
            
            <div class="headerItem console">
                <button nbButton outline shape="round" (click)="consoleToggleClick()" *ngIf="isAllowedInConsole">{{(isOutsideConsole) ? "Admin Console" : "Leave Console"}}</button>
            </div>
        </div>
         <!--Signed Out Header -->
        <div class="headerRight" *ngIf="!isSignedIn">
            <div class="headerItem console">
                <button nbButton outline (click)="signInClick()">Sign In</button>
            </div>
            <div class="headerItem">
                <button nbButton (click)="createAccountClick()">Create Account</button>
            </div>
        </div>
    
         <!--Menu Items -->
        <div class="headerRight" *ngIf="isOutsideConsole">
            <div class="headerItem action" *ngFor="let item of header.items">
                <button nbButton ghost (click)="headerActionClick(item.link)">{{item.title}}</button>
                <div class="dropdown">
                    <button nbButton ghost *ngFor="let subitem of item.subMenus" (click)="headerActionClick(subitem.link)">{{subitem.title}}</button>
                </div>
            </div>
        </div>
    </div>
    
    <div class="headerContainer wp" *ngIf="isOutsideConsole">
        <app-navbar-rethink></app-navbar-rethink>
        <div [className]="'wpBelowHeaderItems' + (winGlobal.MOBILE_MODE ? ' mobile' : '')" *ngIf="showBelowHeaderButtons">
            <div *ngIf="isSignedIn" class="wpProfileGroup" [ngClass]="currentUrl == 'practice' ? 'practice': ''">
                <div class="wpProfile">
                    <button class="wpProfileBtn">
                        <nb-icon class="personIcon" *ngIf="false" icon="person"></nb-icon>
                        <div class="userDetails">
                            Welcome<br/>
                             <b>{{user.name}}</b><br/>
                             {{user.email}}
                        </div>
                        <nb-icon class="arrowIcon" icon="arrow-down"></nb-icon>
                    </button>
                 </div>
        
                 <div class="account-settings">
                    <button (click)="profileActionClick('myaccount')">ACCOUNT SETTINGS</button><br/>
                    <ng-container class="wpActionButtons" *ngIf="currentUrl != 'practice'">
                        <button (click)="profileActionClick('managesub')">MANAGE SUBSCRIPTION</button><br/>
                        <button (click)="profileActionClick('signout')">LOG OUT</button>
                    </ng-container>
                 </div>
                 
            </div>
    
            <button *ngIf="!isSignedIn" nbButton outline shape="round" class="loginButton" status="primary" (click)="signInClick()">LOG IN</button>
    
            <!--<button *ngIf="!isSubscribed" nbButton shape="round" class="subscribeButton" status="primary" (click)="subscribeClick()">SUBSCRIBE</button>-->
    
            <button *ngIf="(isAllowedInConsole && isSignedIn)" nbButton outline shape="round" class="adminButton" (click)="consoleToggleClick()">ADMIN CONSOLE</button>
        </div>
    </div>
    
    
    <!-- OLD HEADER -->
    <div class="headerContainer wp" *ngIf="false">
        <div class="headerLeft">
            <div class="headerItem logo" *ngIf="isOutsideConsole">
                <img [src]='(isLightTheme) ? "/assets/images/rethink-logo-cl.png" : "/assets/images/rethink-logo-bw.png"'>
            </div>
            <div class="headerItem menu" *ngIf="!isOutsideConsole">
                <button nbButton ghost (click)="menuClick()">
                    <nb-icon icon="menu-outline"></nb-icon>
                </button>
            </div>
            <div class="headerItem title" *ngIf="!isOutsideConsole">
                <div class="text">DLZP CMS</div>
            </div>
        </div>
        <!-- Signed In Header -->
        <div class="headerRight" *ngIf="isSignedIn">
            <div class="headerItem profile">
                <button nbButton (click)="profileClick()" (focusout)="profileClickOut($event)">
                    <nb-icon icon="person"></nb-icon>
                </button>
                <div class="profileActions" *ngIf="isProfileActionsToggled">
                    <div class="profileAction">
                        <br style="clear: both;"/>
                        <button nbButton fullWidth status="basic" class="profileActionButton preventFocusOut" outline (click)="profileActionClick('myaccount')" (focusout)="profileClickOut($event)">My Account</button>
                    </div>
                    <div class="profileAction">
                        <br style="clear: both;"/>
                        <button nbButton fullWidth status="danger" class="profileActionButton preventFocusOut" outline (click)="profileActionClick('signout')" (focusout)="profileClickOut($event)">Sign Out</button>
                    </div>
    
                </div>
            </div>
            
            
            <div class="headerItem console">
                <button nbButton outline (click)="consoleToggleClick()" *ngIf="isAllowedInConsole">{{(isOutsideConsole) ? "Admin Console" : "Leave Console"}}</button>
            </div>
        </div>
        <!-- Signed Out Header -->
        <div class="headerRight" *ngIf="!isSignedIn">
            <div class="headerItem console">
                <button nbButton outline (click)="signInClick()">Sign In</button>
            </div>
            <div class="headerItem">
                <button nbButton (click)="createAccountClick()">Create Account</button>
            </div>
        </div>
    
        <!-- Menu Items -->
        <div class="headerRight" *ngIf="isOutsideConsole">
            <div class="headerItem action" *ngFor="let item of header.items">
                <button nbButton ghost (click)="headerActionClick(item.link)">{{item.title}}</button>
                <div class="dropdown">
                    <button nbButton ghost *ngFor="let subitem of item.subMenus" (click)="headerActionClick(subitem.link)">{{subitem.title}}</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<!--<ng-template #lmexercisenav>-->
<!--    <div class="headerContainer wp lms-exercise">-->
<!--        <div [className]="'wpBelowHeaderItems' + (winGlobal.MOBILE_MODE ? ' mobile' : '')" *ngIf="showBelowHeaderButtons">-->
<!--            <div *ngIf="isSignedIn" class="wpProfileGroup">-->
<!--                <div class="wpProfile">-->
<!--                    <button class="wpProfileBtn">-->
<!--                        <nb-icon class="personIcon" *ngIf="false" icon="person"></nb-icon>-->
<!--                        <div class="userDetails">-->
<!--                            Welcome<br/>-->
<!--                             <b>{{user.name}}</b><br/>-->
<!--                             {{user.email}}-->
<!--                        </div>-->
<!--                        <nb-icon class="arrowIcon" icon="arrow-down"></nb-icon>-->
<!--                    </button>-->
<!--                 </div>-->
        
<!--                 <div class="account-settings">-->
<!--                    <button (click)="profileActionClick('myaccount')">ACCOUNT SETTINGS</button><br/>-->
<!--                 </div>-->
                 
<!--            </div>-->
    
            <!--<button *ngIf="!isSignedIn" nbButton outline shape="round" class="loginButton" status="primary" (click)="signInClick()">LOG IN</button>-->
    
            <!--<button *ngIf="!isSubscribed" nbButton shape="round" class="subscribeButton" status="primary" (click)="subscribeClick()">SUBSCRIBE</button>-->
    
            <!--<button *ngIf="(isAllowedInConsole && isSignedIn)" nbButton outline shape="round" class="adminButton" (click)="consoleToggleClick()">ADMIN CONSOLE</button>-->
<!--        </div>-->
<!--    </div>-->
<!--</ng-template>-->