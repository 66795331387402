var commanumber = require('comma-number');

export namespace UTIL {
    // Switch Exhaustive Checking
    export function assertUnreachable(x: never): never {
        throw new Error("Exhaustive Search End" + x);
    }

    // Made Random ID
    const ID_LENGTH = 15;
    export function makeId() {
        let length = ID_LENGTH;
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    // Get URL Params From String
    export function getURLParams(url) {
        var params = {};
        var parser = document.createElement('a');
        parser.href = url;
        var query = parser.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) != "") {
                params[pair[0]] = decodeURIComponent(pair[1]);
            }
        }
        return params;
    };

    // Adds commas as used in american decimals ex 1,000,000.00
    export function numberWithCommas(x) {
        return commanumber(parseFloat(x.toString()));
    }

    // Check if a property is usable
    export function doesExist(variable): boolean {
        return !(typeof variable === 'undefined' || variable === null);
    }

    // Make a property a required input
    export function makeRequired(objectName: string, parentName: string, object) {
        if (!doesExist(object)) { throw "'" + objectName + "' is a required input of '" + parentName + "'!" };
    }

    // sleep
    export const sleep = delay => new Promise(resolve => setTimeout(resolve, delay));

    // Convert string to hashed HEX
    
    export function stringToRGB(str){
        str = str + "bGASggAsfds";
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
           hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let h = hash;
        var c = (h & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();
    
        return "00000".substring(0, 6 - c.length) + c;
    }

    export function getFirstKeyValueMatch<T>(set: T[], key: keyof T, value: T[keyof T]): T {
        for (let o of set) {
            if (o[key] == value) {
                return o;
            }
        }
    }

    // Link a variable with an async value to be changed after resolution. Optionally specifify default to be used during wait
    /*export function asyncLink<T extends object>(promiseToResolve: Promise<T>, defaultValue: T): T {
        let obj = defaultValue;
        promiseToResolve.then((v) => { obj = v; }).catch((r) => { throw 'Background promise failed. Reason: ' + r; });
        setTimeout(function asfd(obj) {
            console.log("AGG");
            console.log(this.obj);
            this.obj = { videoRef: "AFJKOGLNA"}
        }.bind(this, obj), 500);
        return obj;
    }*/
}
