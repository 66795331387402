import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsoleComponent } from './console.component';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
    NbThemeModule,
    NbLayoutModule,
    NbButtonModule,
    NbIconModule,
    NbSidebarModule,
    NbMenuModule,
    NbCardModule,
    NbToggleModule,
    NbTabsetModule,
    NbAccordionModule,
    NbInputModule,
    NbSpinnerModule,
    NbDialogModule,
    NbFormFieldModule,
    NbTooltipModule,
    NbCheckboxModule,
    NbSelectModule
} from '@nebular/theme';
import { AboutComponent } from './pages/about/about.component';
import { AppRoutingModule } from '../app-routing.module';
import { AppsComponent } from './pages/apps/apps.component';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';
import { NavbarModule } from './../navbar/navbar.module';
import { UsersComponent } from './pages/users/users.component';
import { SubscriptionsModule } from '../apps/subscriptions/subscriptions.module';
import { LogsComponent } from './pages/logs/logs.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { FormsModule } from '@angular/forms';
import { UploadPaymentCredsPopupComponent } from './popup/popups/upload-payment-creds-popup/upload-payment-creds-popup.component';
import { PopupFrameComponent } from './popup/popups/popup-frame/popup-frame.component';
import { DeletePaymentCredsPopupComponent } from './popup/popups/delete-payment-creds-popup/delete-payment-creds-popup.component';
import { UserActionsPopupComponent } from './popup/popups/user-actions-popup/user-actions-popup.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { DeactivateplanwarnComponent } from './popup/popups/deactivateplanwarn/deactivateplanwarn.component';
import { PlanactionswarnComponent } from './popup/popups/planactionswarn/planactionswarn.component';

@NgModule({
    declarations: [
        ConsoleComponent,
        AboutComponent,
        AppsComponent,
        SubscriptionsComponent,
        UsersComponent,
        LogsComponent,
        PaymentsComponent,
        UploadPaymentCredsPopupComponent,
        PopupFrameComponent,
        DeletePaymentCredsPopupComponent,
        UserActionsPopupComponent,
        SettingsComponent,
        DeactivateplanwarnComponent,
        PlanactionswarnComponent
    ],
    imports: [
        CommonModule,
        AppRoutingModule,

        NavbarModule,
        SubscriptionsModule,

        NbThemeModule.forRoot({ name: 'default' }),
        NbLayoutModule,
        NbEvaIconsModule,
        NbButtonModule,
        NbIconModule,
        NbSidebarModule,
        NbMenuModule,
        NbCardModule,
        NbToggleModule,
        NbTabsetModule,
        NbAccordionModule,
        NbInputModule,
        NbSpinnerModule,
        FormsModule,
        NbDialogModule.forChild(),
        NbFormFieldModule,
        NbTooltipModule,
        NbCheckboxModule,
        NbSelectModule
    ],
    exports: [
        ConsoleComponent
    ]
})
export class ConsoleModule { }
