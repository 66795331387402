import { Subscriptions } from "../Subscriptions";
import { Validation } from "./Validation";


export namespace SubscriptionsValidation {
    export function subscriptions_package(p: Subscriptions.PackageModel): Validation.Validations {
        let response : Validation.Validations = [
            {
                condition: (p.ccEnrollmentPolicy == undefined || typeof p.ccEnrollmentPolicy != "string" || p.ccEnrollmentPolicy.length < 1),
                error: { objectPath: ['ccEnrollmentPolicy'], message: "The chosen enrollment policy is invalid or missing" }
            },
            {
                condition: (p.dateCreated == undefined || typeof p.dateCreated != "number"),
                error: { objectPath: ['dateCreated'], message: "The date created is invalid." }
            },
            {
                condition: (p.name == undefined || typeof p.name != "string"),
                error: { objectPath: ['name'], message: "The name entered is invalid." }
            },
            {
                condition: (p.name.length < 1),
                error: { objectPath: ['name'], message: "The package must have a name." }
            },
            {
                condition: (p.accessRole == undefined || typeof p.accessRole != "string"),
                error: { objectPath: ['accessRole'], message: "The access role is invalid or undefined." }
            },
            {
                condition: (p.accessRole.length < 1),
                error: { objectPath: ['accessRole'], message: "The package must have an accessRole." }
            },
        ];

        for (let planI in p.plans) {
            let plan = p.plans[planI];
            let planV = subscriptions_plan(plan);
            for (let v of planV) {
                v.error.message = "Plan in this package: " + v.error.message;
                let newPath = ['plans', planI].concat(v.error.objectPath);
                v.error.objectPath = JSON.parse(JSON.stringify(newPath));
                response.push(v);
            }
        }

        return response;
    }

    export function subscriptions_plan(p: Subscriptions.PlanModel): Validation.Validations {
        return [
            {
                condition: (p.name == undefined || typeof p.name != "string"),
                error: { objectPath: ['name'], message: "The name entered is invalid." }
            },
            {
                condition: (p.name.length < 1),
                error: { objectPath: ['name'], message: "The plan must have a name." }
            },
            {
                condition: (p.accessRole == undefined || typeof p.accessRole != "string"),
                error: { objectPath: ['accessRole'], message: "The access role is invalid or undefined." }
            },
            {
                condition: (p.accessRole.length < 1),
                error: { objectPath: ['accessRole'], message: "The plan must have an accessRole." }
            },
            {
                condition: (p.billPeriodDurationInDays == undefined || typeof p.billPeriodDurationInDays != "number"),
                error: { objectPath: ['billPeriodDurationInDays'], message: "The billing period is invalid or undefined." }
            },
            {
                condition: (p.billPeriodDurationInDays < 1),
                error: { objectPath: ['billPeriodDurationInDays'], message: "The billing period must be at least one day long." }
            },
            {
                condition: (p.billPrice == undefined || typeof p.billPrice != "number"),
                error: { objectPath: ['billPrice'], message: "The bill price is invalid or undefined." }
            },
            {
                condition: (p.billPrice <= 0),
                error: { objectPath: ['billPrice'], message: "The bill price cannot be negative." }
            },
            {
                condition: (p.currencyCode.length < 1),
                error: { objectPath: ['currencyCode'], message: "The currency selected is invalid." }
            },
            {
                condition: (!Object.keys(Subscriptions.CurrencyList).includes(p.currencyCode)),
                error: { objectPath: ['currencyCode'], message: "The currency selected is invalid." }
            },
            {
                condition: (typeof p.enrollmentPaused != "boolean"),
                error: { objectPath: ['enrollmentPaused'], message: "The selected enrollment status is invalid" }
            },
            {
                condition: (typeof p.billingPaused != "boolean"),
                error: { objectPath: ['billingPaused'], message: "The selected billing status is invalid" }
            },
            {
                condition: (!Object.keys(Subscriptions.OnUnsubscribePolicyList).includes(p.onUnsubscribe)),
                error: { objectPath: ['onUnsubscribe'], message: "The selected on unsubscribe is invalid." }
            },
        ];
    }
}