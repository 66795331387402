import { Component, OnInit } from '@angular/core';
import { Subscriptions } from 'src-shared/Subscriptions';
import { UTIL } from 'src-shared/Utilities';
import { Comms, SubscriptionsRequest } from 'src-shared/CommsRequests';
import { CommsService } from 'src/comms/comms.service';
import { Console } from 'console';
import { Redirect } from 'src/Redirect';
import { Validation } from 'src-shared/Validation/Validation';

@Component({
    selector: 'app-subscriptions-consolepage',
    templateUrl: './subscriptions-consolepage.component.html',
    styleUrls: ['./subscriptions-consolepage.component.scss']
})
export class SubscriptionsConsolepageComponent implements OnInit {

    packages : Subscriptions.PackageModel[] = [];

    sub = Subscriptions;
    editingSub : Subscriptions.PackageModel;
    editorIsLoading = false;
    showEditingSub = false;


    resetSubEditor() {
        let id = UTIL.makeId();
        this.editingSub = {
            accessRole: "subscription-package-" + id,
            ccEnrollmentPolicy: "disallow_cancel",
            dateCreated: (new Date()).getTime(),
            name: "",
            plans: [],
            staticID: id,
        }
    }
    createPackageClick() {
        this.resetSubEditor();
        this.showEditingSub = true;
    }
    cancelPackageClick() {
        console.log(Validation.verify({
            id: "subscriptions_package",
            t: this.packages[0]
        }))
        this.showEditingSub = false;
    }
    async savePackageClick() {
        this.editorIsLoading = true;
        let response = await this.comms.request({
            id: "subscriptions_createPackage",
            payload: this.editingSub
        });
        if (response.payload != Comms.SuccessResponse) {
            // TODO: Handle Error
        } else {
            this.editorIsLoading = false;
            this.resetSubEditor();
            this.showEditingSub = false;
            Redirect.reload();
        }
    }

    async fetchPackages() {
        // TODO: Loading Animation
        let response = await this.comms.request({
            id: "subscriptions_getAll",
            payload: {}
        });
        if (response.error) {
            // TODO: Handle Error
        } else {
            this.packages = (<SubscriptionsRequest.GetAllResponse>response.payload).packages
        }
    }

    getKeys(a) {
        return Object.keys(a);
    }

    constructor(private comms : CommsService) {
    }

    ngOnInit(): void {
        this.resetSubEditor();
        this.fetchPackages();
    }

}
