<!-- DEBUG THEMES TODO: REMOVE-->
<div *ngIf="false" style="position: fixed; bottom: 10px; left: 10px; z-index: 9999;">
    <button (click)="setTheme('default')">default</button>
    <button (click)="setTheme('corporate')">corporate</button>
    <button (click)="setTheme('cosmic')">cosmic</button>
    <button (click)="setTheme('dark')">dark</button>
    <button (click)="setTheme('rethinkdark')">Rethink Dark</button>
    <button (click)="setTheme('rethinklight')">Rethink Light</button>
    <button (click)="setTheme('wplight')">WP Light</button>
    <button (click)="setTheme('wpdark')">WP Dark</button>
</div>

<router-outlet></router-outlet>

