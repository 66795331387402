<nb-layout>
    <nb-sidebar [state]="sidebarState">
        <nb-menu [items]="sidebarItems">
        </nb-menu>
    </nb-sidebar>
    
    
    <nb-layout-header fixed>
        <app-navbar (menuToggle)="menuClick()"></app-navbar>
    </nb-layout-header>

    <nb-layout-column>
        <router-outlet></router-outlet>
    </nb-layout-column>

</nb-layout>