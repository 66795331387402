import { Component, OnInit } from '@angular/core';
import { Redirect } from 'src/Redirect';
import { AuthService } from 'src/auth/auth.service';
import { UTIL } from 'src-shared/Utilities';
import { NbThemeService } from '@nebular/theme';
import { RethinkCustomService } from 'src/rethink/rethink-custom';
import { CommsService } from 'src/comms/comms.service';

@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

    email: string = "";
    password: string = "";
    invalid = false;
    invalidMessage = "There was an error signing in.";
    loading = false;

    // Standard Return Path for no Rebound
    reboundPath: string = "/";

    async signUpClick() {

        Redirect.to(Redirect.DEF.Subscribe);
    }

    goHomeClick() {
        Redirect.to(Redirect.DEF.Home);
    }

    inputKeyup(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.signInClick();
        }
    }

    async signInClick() {
        this.loading = true;
        try {
            var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if(!emailPattern.test(this.email)) { throw {message:'Invalid email format'}; }
            let user = await this.authService.signin({
                email: this.email,
                password: this.password,
            });
            this.loading = false;
            
            console.log('HTIS USER',user)
            window.location.href = this.reboundPath;
        } catch (err) {
            this.invalid = true;
            this.invalidMessage = err.message;
            this.loading = false;
            if (err.message) {
                console.log(err.message);
            }
            console.log(err);
        }
    }

    forgotPWClick() {
        Redirect.to(Redirect.DEF.ForgotPassword);
    }

    constructor(private authService: AuthService, private themeService: NbThemeService, private rethink: RethinkCustomService, private comms: CommsService) { }

    ngOnInit(): void {
        this.themeService.changeTheme('rethinkdark');
        let params = UTIL.getURLParams(window.location.href);
        if (Object.keys(params).includes('rebound')) {
            this.reboundPath = params['rebound'];
        }
    }

}
