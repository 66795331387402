import { Component, OnInit } from '@angular/core';
import { Redirect } from 'src/Redirect';
import { AuthService } from 'src/auth/auth.service';
import { NbThemeService } from '@nebular/theme';
import { Validation } from 'src-shared/Validation/Validation';
import { AuthTypes } from 'src-shared/Auth';
import { UTIL } from 'src-shared/Utilities';
import { RethinkCustomService } from 'src/rethink/rethink-custom';

type SignUpState = 'start' | 'details' | 'finish';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {


    email: string = "";
    password: string = "";
    confirmPassword: string = "";
    name: string = "";
    company: string = "";
    jobTitle: string = "";
    managesubsLink = Redirect.DEF.ManageSubscriptions;
    marketsTraded: { name: string, value: boolean; }[] = [
        { name: "Trader", value: false },
        { name: "Professional/Elite Athlete", value: false },
        { name: "Business Executive", value: false },
        { name: "Entrepreneur", value: false },
        { name: "Investor", value: false },
        { name: "Actor/Producer/Director/Agent", value: false },
        { name: "Other", value: false },
    ];
    isLoading = false;
    invalid = false;
    invalidMessage = "Error";
    isSignedIn = false;

    nextClick() {

    }
    async createClick() {
        this.isLoading = true;
        let userobject: AuthTypes.SignUpUser = {
            name: this.name,
            email: this.email,
            password: this.password,
            confirmPassword: this.confirmPassword,
            company: this.company,
            jobtitle: this.jobTitle,
            occupation: JSON.stringify(this.marketsTraded)
        };
        try {
            let results = Validation.verify({
                t: userobject,
                id: "auth_signupuser"
            });
            if (results != "Valid") {
                throw {message: results.errors[0].message}
            }

            let userConfirm = await this.authService.signup(userobject);
            if (!userConfirm.userConfirmed) { await UTIL.sleep(1000); }
            let userLogin = await this.authService.signin({
                email: userobject.email,
                password: userobject.password
            });
            Redirect.to(Redirect.DEF.Payment);
        } catch (err) {
            if (err.message) {
                console.log(err.message);
            }
            console.log(err);
            this.isLoading = false;
            this.invalid = true;
            this.invalidMessage = err.message;
        }



    }
    async checkRedir() {
        if (await this.authService.isSignedIn()) {
            if (!(await this.rethink.IsValidSubscriber())) {
                Redirect.to(Redirect.DEF.Payment);
                return;
            }
        }
    }
    signInClick() {
        Redirect.to(Redirect.DEF.SignIn);
    }
    logOutClick() {
        Redirect.to(Redirect.DEF.DoLogout);
    }
    goHomeClick() {
        Redirect.to(Redirect.DEF.Home);
    }
    async configure() {
        this.themeService.changeTheme('rethinkdark');
        this.isSignedIn = await this.authService.isSignedIn();
    }

    constructor(
        private authService: AuthService,
        private themeService: NbThemeService,
        private rethink: RethinkCustomService
    ) { }

    ngOnInit(): void {
        this.checkRedir();
        this.configure();
    }

}
