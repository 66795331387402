import { Component, OnInit } from '@angular/core';
import { CommsService } from 'src/comms/comms.service';
import { Redirect } from 'src/Redirect';
import { Subscriptions } from 'src-shared/Subscriptions';

@Component({
    selector: 'app-planactionswarn',
    templateUrl: './planactionswarn.component.html',
    styleUrls: ['./planactionswarn.component.scss']
})
export class PlanactionswarnComponent implements OnInit {

    actions = [
        {
            id: 'commit',
            text: '',
            status: 'danger'
        },
        {
            id: 'close',
            text: 'Close',
            status: 'basic',
            isCloseButton: true
        }
    ];

    loading = false;
    headtitle = '';
    statuscolor = '';
    pname = '';
    packageID;
    plan: Subscriptions.PlanModel;
    action: 'penroll' | 'pbilling' | 'renroll' | 'rbilling' = 'renroll';
    errtext = "";

    async actionClick(id) {
        if (id == 'commit') {
            this.loading = true;
            let plan = JSON.parse(JSON.stringify(this.plan)) as Subscriptions.PlanModel;
            switch (this.action) {
                case 'penroll': plan.enrollmentPaused = true; break;
                case 'pbilling': plan.billingPaused = true; break;
                case 'renroll': plan.enrollmentPaused = false; break;
                case 'rbilling': plan.billingPaused = false; break;
            }
            let res = await this.comms.request({
                id: "subscriptions_updatePlan",
                payload: {
                    packageID: this.packageID,
                    plan: plan
                }
            });
            if (res.payload == 'Success') { Redirect.reload(); }
            if (res.error && typeof res.error == "string") {
                this.errtext = res.error;
                this.loading = false;
            }
        }
    }

    constructor(private comms: CommsService) { }

    ngOnInit(): void {
        switch (this.action) {
            case "penroll":
                this.headtitle = 'Pause Plan Enrollment';
                this.statuscolor = 'warning';
                this.actions[0].text = 'Pause';
                break;
            case "renroll":
                this.headtitle = 'Resume Plan Enrollment';
                this.statuscolor = 'success';
                this.actions[0].text = 'Resume';
                break;
            case "pbilling":
                this.headtitle = 'Pause Plan Billing';
                this.statuscolor = 'danger';
                this.actions[0].text = 'Pause';
                break;
            case "rbilling":
                this.headtitle = 'Resume Plan Billing';
                this.statuscolor = 'success';
                this.actions[0].text = 'Resume';
                break;
        }
        this.actions[0].status = this.statuscolor;
    }

}
