
<nb-card [status]="status" class="main" [nbSpinner]="loading" nbSpinnerStatus="primary">
    <nb-card-header [className]="extraTallHeader ? 'extraTallHeader' : header != '' ? 'tallHeader' : 'tallHeader'">
        {{header}}
        <button nbButton ghost class="close" shape="round" (click)="close()">
            <nb-icon icon="close"></nb-icon>
        </button>
    </nb-card-header>
    <nb-card-body>
        <ng-content></ng-content>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton [status]="a.status" *ngFor="let a of actions" (click)="actionClick(a.id)">{{a.text}}</button>
    </nb-card-footer>
</nb-card>
