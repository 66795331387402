<div class="page">
    <ng-container *ngFor="let pkg of packages">
        <app-subscriptions-package *ngIf="!pkg.deactivated" [package]="pkg"></app-subscriptions-package>
    </ng-container>
    


    <nb-card class="editSubCard" *ngIf="showEditingSub" [nbSpinner]="editorIsLoading" nbSpinnerStatus="primary">
        <nb-card-header><input nbInput placeholder="Subscription Package Name" [(ngModel)]="editingSub.name"></nb-card-header>
        <nb-card-body>
            <div class="detail">
                <div class="detailName">CONCURRENT ENROLLMENT</div>
                <div class="detailText">Choose what happens if a user attempts to enroll in multiple billing plans within this package.</div>
                <nb-radio-group [(ngModel)]="editingSub.ccEnrollmentPolicy">
                    <nb-radio *ngFor="let p of getKeys(sub.ConcurrentEnrollmentPolicyList)" [value]="p" [disabled]="p != 'disallow_cancel'">{{sub.ConcurrentEnrollmentPolicyList[p].description}}</nb-radio>
                </nb-radio-group>
            </div>
            <br />
            <div></div>
            <div class="detail">
                <div class="detailName">ACCESS ROLE</div>
                <div class="detailText">The role given to all users enrolled in at least one billing plan in this package.</div>
                <input nbInput fieldSize="small" [(ngModel)]="editingSub.accessRole">

            </div>
        </nb-card-body>


        <nb-card-footer>
            <button *ngIf="true" nbButton status="success" (click)="savePackageClick()">SAVE PACKAGE</button>
            <button *ngIf="!true" nbButton status="primary">SAVE CHANGES</button>
            <button nbButton status="basic" (click)="cancelPackageClick()">CANCEL</button>
        </nb-card-footer>
    </nb-card>


    <button class="addButton" (click)="createPackageClick()">
        <nb-icon icon="plus-circle"></nb-icon>
        <br style="clear: both;" />
        <b>CREATE SUBSCRIPTION PACKAGE</b>
    </button>
</div>