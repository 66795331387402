import { Subscriptions } from '../Subscriptions';
import { UTIL } from '../Utilities';
import { SubscriptionsValidation } from './SubscriptionsValidation';
import { AuthTypes } from '../Auth';
import * as AuthValidation from './AuthValidation';

// This is where input field validation is done for BOTH server and client.
// The client can use it to verify the general validity of a request,
// but the server will strictly enforce these rules. 

// Modifyable Start

type ValidationTypes_DEFINE =
    Validation.Make<"subscriptions_package", Subscriptions.PackageModel> |
    Validation.Make<"subscriptions_plan", Subscriptions.PlanModel> |
    Validation.Make<"auth_signupuser", AuthTypes.SignUpUser>

// Modifyable End

export namespace Validation {
    // Append Validation Functions Here
    export function getChecks(a: Types): Validations {
        switch (a.id) {
            case "subscriptions_package": return SubscriptionsValidation.subscriptions_package(a.t);
            case "subscriptions_plan": return SubscriptionsValidation.subscriptions_plan(a.t);
            case "auth_signupuser": return AuthValidation.auth_signupuser(a.t);

            default: UTIL.assertUnreachable(a);
        }
    }


    export type Make<ID extends string, T> = {
        id: ID,
        t: T;
    };

    export type Types = ValidationTypes_DEFINE;

    export type Error = {
        objectPath: string[],
        message: string;
    };

    export type Valid = "Valid";
    export type Invalid = {
        errors: Validation.Error[];
    };
    export type Result = Valid | Invalid;

    export type Validations = {
        condition: boolean,
        error: Validation.Error;
    }[];


    export function getResult(validations: Validations): Validation.Result {
        let result: Validation.Invalid = { errors: [] };
        for (let v of validations) {
            if (v.condition) {
                result.errors.push(v.error);
            }
        }
        if (result.errors.length == 0) { return 'Valid'; } else { return result; }
    }
    export function verify(a: Types): Validation.Result {
        let checks = getChecks(a);
        return getResult(checks);
    }

}

