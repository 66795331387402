import { Component, OnInit, Input, Output, ElementRef, EventEmitter } from '@angular/core';
import { ConsolepopupService } from '../../consolepopup.service';

@Component({
    selector: 'app-popup-frame',
    templateUrl: './popup-frame.component.html',
    styleUrls: ['./popup-frame.component.scss']
})
export class PopupFrameComponent implements OnInit {

    @Input() header = '';
    @Input() status: 'primary' | 'basic' | 'success' | 'danger' | 'warning' | 'info' | '' = 'info';
    @Input() extraTallHeader = false;
    @Input() actions: actionModel[] = [];
    @Input() loading: boolean = false;

    @Output() action: EventEmitter<string> = new EventEmitter();

    actionClick(id) {
        this.action.emit(id);
        for (let a of this.actions) {
            if (id == a.id) {
                if (a.isCloseButton) {
                    this.ds.close();
                }
            }
        }
    }

    close() {
        this.ds.close();
    }

    constructor(private ds : ConsolepopupService) { }

    ngOnInit(): void {
    }

}

export type actionModel = {
    id: string,
    text: string,
    status?: string
    isCloseButton?: boolean
}