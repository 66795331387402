<h1>Users</h1>
<div class="contentDiv">
    <nb-card [nbSpinner]="usersLoading" nbSpinnerStatus="primary">
        <nb-card-body style="padding: 0;">
            <nb-form-field>
                <nb-icon nbPrefix icon="search"></nb-icon>
                <input type="text" nbInput fieldSize="large" fullWidth shape="round" placeholder="Search Users By Email Prefix" [(ngModel)]="searchKey">
            </nb-form-field>
            <div class="searchButtons">
                <button nbButton status="primary" shape="round" (click)="searchClick()">Search</button>
                <button nbButton shape="round" (click)="searchClear()">Clear</button>
            </div>
            
        </nb-card-body>
    </nb-card>

    <table class="userTable" *ngIf="!usersLoading">
        <tr>
            <th>#</th>
            <th>EMAIL</th>
            <th></th>
            <th>NAME</th>
            <th>ROLES</th>
            <th>JOINED</th>
            <th style="text-align: center;"></th>
        </tr>
        <tr *ngFor="let u of users; let odd = odd; let i = index;" [className]="odd ? 'oddTr' : ''">
            <td class="n">{{i + 1}}</td>
            <td>
                {{u.email}}
            </td>
            <td>
                <nb-icon nbTooltip="Email Not Verified" *ngIf="!u.emailVerified" status="danger" icon="close-outline"></nb-icon>
                <nb-icon nbTooltip="Email Verified" *ngIf="u.emailVerified" status="success" icon="checkmark"></nb-icon>
            </td>
            <td>
                {{u.name}}
            </td>
            <td>
                <div *ngIf="u.roles">
                    <div class="role" style="--roleC:{{r.color}}" *ngFor="let r of u.roles">
                        {{r.name}}
                    </div>
                </div>
                <div *ngIf="!u.roles">
                    <button class="roleFetchBtn" nbButton status="primary" ghost (click)="fetchRoles(u)">Fetch</button>
                </div>
            </td>
            <td>
                {{u.joined}}
            </td>
            <td class="actionsCol">
                <button shape="round" nbButton ghost (click)="actionsClick(u)">
                    <nb-icon icon="more-horizontal-outline"></nb-icon>
                </button>
            </td>
        </tr>
    </table>
    <br />
    <button nbButton
        status="primary"
        ghost [nbSpinner]="usersLoading"
        nbSpinnerStatus="primary" 
        style="width: max-content; display:block; margin:auto;" 
        *ngIf="pToken" 
        (click)="loadMore()">
        Load More
    </button>
</div>