<h1>Apps and Features</h1>
<div class="contentDiv">
    <nb-card>
        <nb-card-body style="padding-top: 7px;">
            <div  style="text-align: right; font-size: 10px; padding-right: 3px; "><i>Show/Hide</i></div>
            <div class="feature" *ngFor="let f of featureList">
                <div class="name">
                    <nb-icon icon="{{f.icon}}"></nb-icon>{{f.name}}
                </div>
                <div class="rightElements">
                    <nb-toggle status="success" [(checked)]="f.state" [disabled]="f.disabled" (checkedChange)="click(f)"></nb-toggle>
                </div>
                
            </div>
        </nb-card-body>
    </nb-card>
</div>