import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommsService } from 'src/comms/comms.service'
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ConsoleModule } from './console/console.module';
import { AuthModule } from './auth/auth.module';

import {
    NbThemeModule,
    NbLayoutModule,
    NbButtonModule,
    NbIconModule,
    NbSidebarModule,
    NbMenuModule,
    NbDialogModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { AppRoutingModule } from './app-routing.module';



@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,

    // Internal
    ConsoleModule,
    AuthModule,

    // Nebular
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbButtonModule,
    NbIconModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),

  ],
  providers: [
      CommsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
