import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignupComponent } from './signup/signup.component';
import { NbLayoutModule, NbCardModule, NbInputModule, NbButtonModule, NbIconModule, NbSpinnerModule, NbCheckboxModule } from '@nebular/theme';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { SigninComponent } from './signin/signin.component';
import { FormsModule } from '@angular/forms';
import { NavbarModule } from '../navbar/navbar.module';
import { VerifyemailComponent } from './verifyemail/verifyemail.component';



@NgModule({
  declarations: [SignupComponent, ForgotpasswordComponent, SigninComponent, VerifyemailComponent],
  imports: [
    CommonModule,
    FormsModule,
    NbLayoutModule,
    NbCardModule,
    NbInputModule,
    NbButtonModule,
    NbIconModule,
    NavbarModule,
    NbSpinnerModule,
    NbCheckboxModule
  ]
})
export class AuthModule { }
