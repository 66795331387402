import { Injectable } from '@angular/core';
import { CommsService } from './comms.service';
import { Comms } from '../../src-shared/CommsRequests';

@Injectable({
    providedIn: 'root'
})
export class CachedService {

    async triggerFetch(r, cb) {
        let req = await this.comms.request(r);
        localStorage['CACHECOMMSDIR_'+r.id] = JSON.stringify(req);
        cb(req);
        return req;
    }

    async request<R extends Comms.Request>(request: Comms.Request, callback: (x) => any): Promise<Comms.Response<R>> {
        let dir = localStorage['CACHECOMMSDIR_'+request.id];
        if (dir != undefined && dir != null) {
            let pending = this.triggerFetch(request, callback);
            return JSON.parse(dir);
        }
        return (await this.triggerFetch(request, function() {})) as any;
    }

    constructor(private comms: CommsService) { }
}


type CacheDirectory = {
    [key in Comms.RequestID]: any
};