import { Component, OnInit } from '@angular/core';
import { NbThemeService, NbSidebarComponent, NbMenuItem } from '@nebular/theme';
import { RouterOutlet } from '@angular/router';
import { Redirect } from 'src/Redirect';

@Component({
    selector: 'app-console',
    templateUrl: './console.component.html',
    styleUrls: ['./console.component.scss']
})
export class ConsoleComponent implements OnInit {

    // Nav Bar
    isSignedIn = false;
    menuClick() {
        this.sidebarState = (this.sidebarState == "collapsed") ? "expanded" : "collapsed";
    }
    signInClick() {
        Redirect.to(Redirect.DEF.SignIn);
    }
    createAccountClick() {
        Redirect.to(Redirect.DEF.Subscribe);
    }

    // Side Bar
    sidebarItems: (NbMenuItem & { id?:string })[] = [
        {
            title: "About",
            icon: "info-outline",
            link: "./about"
        },
        {
            title: "Apps & Features",
            icon: "grid-outline",
            link: "./apps"
        },
        {
            title: "Users",
            icon: "people-outline",
            link: "./users"
        },
        {
            title: "System Logs",
            icon: "file-text-outline",
            link: "./logs"
        },
        {
            title: "Payments",
            icon: "credit-card-outline",
            link: "./payments",
            id: "payments"
        },
        {
            title: "Subscriptions",
            icon: "shopping-cart-outline",
            link: "./subscriptions",
            id: "subscriptions"
        },
        {
            title: "Site Settings",
            icon: "settings-outline",
            link: "./settings"
        }
    ]
    sidebarState: "collapsed" | "expanded" | "compacted" = "expanded"

    setTheme(theme: 'dark' | 'default' | 'cosmic' | 'corporate') {
        this.themeService.changeTheme(theme);
    }

    constructor(private themeService: NbThemeService) {
        
    }

    ngOnInit(): void {
        for (let i of this.sidebarItems) {
            if (i.id) {
                i.hidden = localStorage["FEATURESHOW_"+i.id] == 'false'
            }
        }
        this.setTheme("default");
    }

}
