<h1>Payments</h1>
<div class="contentDiv">
    <input style="opacity: 0;position: absolute;">
    <input type="password" style="opacity: 0;position: absolute;">
    <nb-card [nbSpinner]="isLoading" [nbSpinnerStatus]="'primary'" [status]="verifyFail ? 'danger' : verifyPass ? 'success' : ''">
        <nb-card-header>

            Connect a PayPal REST API App to receive payments from users on your site.
        </nb-card-header>
        <nb-card-body>
            <div class="textfield">
                <div>
                    <b>Client ID</b>
                    <br />
                    <input autocomplete="off" nbInput fullWidth status="primary" fieldSize="small" style="margin-top: 5px;" [(ngModel)]="clientID">
                </div>
                <br />
                <div>
                    <b>Secret</b>
                    <br />
                    <input autocomplete="off" nbInput fullWidth status="primary" fieldSize="small" style="margin-top: 5px;" type="password" [(ngModel)]="secret">
                    <i *ngIf="secretMatches()" style="font-size: 10px; color: var(--color-basic-700)">The text above is a placeholder. Client secrets cannot be retrieved from the cloud.</i>
                    <i *ngIf="!secretMatches()" style="font-size: 10px; color: transparent">   A</i>
                </div>
            </div>
            <br />
            <div class="actions">
                <button nbButton status="primary" [disabled]="credsMatch()" (click)="uploadClick()">Upload</button>
                <button nbButton status="danger" (click)="deleteClick()">Delete</button>
                <button nbButton status="success" (click)="verifyClick()">Verify Again</button>
            </div>

            
            <nb-card class="verifyShow" [nbSpinner]="verifyLoading" [nbSpinnerStatus]="'primary'" [nbSpinnerSize]="'medium'">
                <nb-card-body>
                    <div *ngIf="verifyPass">
                        <nb-icon icon="checkmark-circle-2" style="color: var(--color-success-500); width: 100%; height: 60px; margin-top: 20px; margin-bottom: 20px;"></nb-icon>
                        <div style="font-weight: bold; width: 100%; text-align: center; font-size: 20px; line-height: 20px; margin-bottom: 10px;">Payment Services Active</div>
                        <div style="width: 400px; text-align: center; font-size: 12px; line-height: 14px; margin: auto; margin-bottom: 20px;">Your API credentials have been verified. Apps and features which utilize Payments will now be able to function.</div>
                    </div>
        
                    <div *ngIf="verifyFail">
                        <nb-icon icon="alert-triangle" style="color: var(--color-danger-500); width: 100%; height: 60px; margin-top: 20px; margin-bottom: 20px;"></nb-icon>
                        <div style="font-weight: bold; width: 100%; text-align: center; font-size: 20px; line-height: 20px; margin-bottom: 10px;">Payment Services Not Active</div>
                        <div style="width: 400px; text-align: center; font-size: 12px; line-height: 14px; margin: auto; margin-bottom: 20px;">Your API credentials could not be verified. Apps and features which utilize Payments will not function.</div>
                    </div>
                </nb-card-body>
            </nb-card>

        </nb-card-body>
    </nb-card>
</div>