<app-popup-frame [header]="'User Actions'" status="info" [actions]="actions" (action)="actionClick($event)" [loading]="loading">
    <div class="main" *ngIf="currentPage == 'actions'">
        <b class="sectionhead">USER DETAILS</b><br/>
        User ID: <b>{{user.id}}</b><br/>
        Email: <b>{{user.email}}</b><br/>
        Name: <b>{{user.name}}</b><br/>
        Date Created: <b>{{user.joined}}</b><br/>
        Roles: <b *ngFor="let r of user.roles" [ngStyle]="{color:r.color}">{{r.name}}  </b><br/>
        Email Verified: <b>{{user.emailVerified}}</b>
        <br/>
        <br/>
        
        <b class="sectionhead">ATTRIBUTES</b><br/>
        Occupation: <b *ngFor="let o of getAttr('custom:occupation')">{{o.value ? o.name + ', ' : ''}}</b><br/>
        Title: <b>{{getAttr('custom:jobtitle')}}</b><br/>
        Company: <b>{{getAttr('custom:company')}}</b><br/>
        Wins: <b>{{getAttr('custom:wins')}}</b><br/>
        Losses: <b>{{getAttr('custom:losses')}}</b><br/>
        <br/>
        
        <button size="small" status="primary" fullWidth nbButton (click)="setPage('editroles')">Edit Roles</button>
        <button size="small" status="primary" fullWidth nbButton (click)="setPage('resetpw')">Set Password</button>
        <button size="small" status="primary" fullWidth nbButton (click)="setPage('changeemail')">Change Email</button>
    </div>

    <div class="main" *ngIf="currentPage == 'editroles'">
        <b class="sectionhead">EDIT ROLES</b><br/>
        <br/>
        <div *ngIf="roles">
            <div *ngFor="let r of roles">
                <nb-checkbox [(checked)]="setRoles[r]">{{r}}</nb-checkbox>
            </div>
        </div>
        
    </div>

    <div class="main" *ngIf="currentPage == 'resetpw'">
        <b class="sectionhead">SET NEW PASSWORD</b><br/>
        <br/>
        <b style="color:var(--color-danger-500);">{{pwError}}</b><br style="clear: both"/><br/>
        <input [(ngModel)]="newPW" nbInput fullWidth [placeholder]="'Enter New Password'">
    </div>

    <div class="main" *ngIf="currentPage == 'changeemail'">
        <b class="sectionhead">CHANGE EMAIL</b><br/>
        <br/>
        <b style="color:var(--color-warning-500);">Changing the users's email will automatically send a verification code to the newly set address.</b>
        <br/><br/>
        <b style="color:var(--color-danger-500);">{{emailError}}</b><br style="clear: both"/><br/>
        <input [(ngModel)]="newEmail" nbInput fullWidth [placeholder]="'Enter New Email'">
    </div>

</app-popup-frame>