import { Component, OnInit } from '@angular/core';
import { CommsService } from 'src/comms/comms.service';
import { LogsRequest } from 'src-shared/CommsRequests';

@Component({
    selector: 'app-logs',
    templateUrl: './logs.component.html',
    styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
    
    logs: LogsRequest.Log[] = [];
    loading = false;
    nextStartKey;

    getDate(t) {
        let d = (new Date());
        d.setTime(t);
        return d.toLocaleString();
    }
    loadClick() {
        this.getLogs();
    }

    async getLogs() {
        this.loading = true;
        let r = await this.comms.request({
            id: "logs_getLogs",
            payoad: {
                n: 10,
                startKey: this.nextStartKey
            }
        });
        
        let keys =[];
        for (let nl of this.logs) {
            keys.push(nl.id);
        }
        for (let l of r.payload.logs) {
            if (!keys.includes(l.id)) {
                keys.push(l.id);
                this.logs.push(l);
            }
        }
        

        this.nextStartKey = (<LogsRequest.GetLogsResponse>(r.payload)).lastkey;
        this.logs.sort(function(a,b) {return b.timeMS - a.timeMS});
        
        this.loading = false;
    }

    async launch() {
        this.logs = [];
        await this.getLogs();    
        
    }

    constructor(private comms: CommsService) {
        this.launch();
    }

    ngOnInit(): void {

    }

}
