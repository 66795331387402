<app-popup-frame [header]="isPackage ? 'Deactivate Subscription Package' : 'Deactivate Billing Plan'" status="danger" [actions]="actions" (action)="actionClick($event)" [loading]="loading">
    <div class="main">
        <div *ngIf="isPackage">
            Deactivate the subscription package named <b>{{pname}}</b>, including all paused/active plans inside this subsciption package.
        </div>
        <div *ngIf="!isPackage">
            Deactivate the billing plan named <b>{{pname}}</b> which is apart of this subscription.
        </div>
        <br />
        <div *ngIf="isPackage">
            Users enrolled in a plan inside this package will be immediately unenrolled and this package will not be available for reactivation in the future.
        </div>
        <div *ngIf="!isPackage">
            Users enrolled in this plan will be immediately unenrolled and this plan will not be available for reactivation in the future.
        </div>
        <br />
        <br />
        <b *ngIf="isPackage">Are you sure you want to permanently deactivate this subscrption package?</b>
        <b *ngIf="!isPackage">Are you sure you want to permanently deactivate this billing plan?</b>
    </div>
</app-popup-frame>