import { Component, OnInit, Input } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { UTIL } from 'src-shared/Utilities';
import { AuthService } from 'src/auth/auth.service';
import { Validation } from 'src-shared/Validation/Validation';
import { Redirect } from 'src/Redirect';

@Component({
    selector: 'app-forgotpassword',
    templateUrl: './forgotpassword.component.html',
    styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

    email = "";
    loading = false;
    
    invalid = false;
    invalidMessage = "";
    
    params : any = {};

    verifyEmail = "";
    newPW = "";
    newPWConfirm = "";

    success = false;

    // Is Verify
    isLoggedIn = false;

    @Input() isVerify = false;

    goHomeClick() {
        Redirect.to(Redirect.DEF.Home);
    }
    async sendLinkClick() {
        this.loading = true;
        this.invalid = false;
        this.invalidMessage = "";
        try {
            await this.auth.sendResetPW(this.email);
            this.verifyEmail = this.email;
            this.params.code = "";
        } catch(e) {
            if (e.message && typeof e.message == 'string') {
                this.invalid = true;
                this.invalidMessage = e.message;
            } 
        }
        this.loading = false;
    }
    isCodeAvail() {
        return typeof this.params.code == 'string';
    }
    async resetPWClick() {
        this.loading = true;
        this.invalid = false;
        this.invalidMessage = "";
        let v = Validation.verify({
            id: "auth_signupuser",
            t: {
                company: "",
                password: this.newPW,
                confirmPassword: this.newPWConfirm,
                email: this.verifyEmail,
                jobtitle: "",
                name: "A",
                occupation: ""
            }
        });
        try {
            
            if (v != "Valid") {
                throw v.errors[0];
            }
            let r = await this.auth.changePW(this.verifyEmail, this.newPW, this.params.code);
            this.success = true;
            console.log(r);
        } catch(e) {
            if (e.message && typeof e.message == 'string') {
                this.invalid = true;
                this.invalidMessage = e.message;
            }
        }
        this.loading = false;
        
    }
    loginClick() {
        Redirect.to(Redirect.DEF.SignIn);
    }

    // Is Verify
    loginVerifyClick() {
        Redirect.toSignInWithRebound(window.location.href);
    }
    async sendVerify() {
        this.loading = true;
        this.invalid = false;
        this.invalidMessage = "";
        try {
            await this.auth.sendEmailVerify();
            this.params.code = "";
        } catch(e) {
            if (e.message && typeof e.message == 'string') {
                this.invalid = true;
                this.invalidMessage = e.message;
            } 
        }
        this.loading = false;
    }
    async verifyClick() {
        this.loading = true;
        this.invalid = false;
        this.invalidMessage = "";
        try {
            await this.auth.submitEmailVerify(this.params.code);
            this.success = true;
        } catch(e) {
            if (e.message && typeof e.message == 'string') {
                this.invalid = true;
                this.invalidMessage = e.message;
            } 
        }
        this.loading = false;
    }


    async configure() {
        if (this.isVerify) {
            this.isLoggedIn = await this.auth.isSignedIn();
        }
        try {
            let usr = await this.auth.getUserDetails();
            this.verifyEmail = usr.email;
            this.email = usr.email;
        } catch(e) {}
    }

    constructor(private themeService: NbThemeService, private auth: AuthService) { }

    ngOnInit(): void {
        this.themeService.changeTheme('rethinkdark');
        this.params = UTIL.getURLParams(window.location.href);
        this.configure()
    }

}
