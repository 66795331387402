



export namespace Exercises {
    export type Exercise = {
        // The Title (may be displayed to user)
        name: string,
        // The key of the object in S3
        s3Key: string,
        // nth exercise in series
        n: number,
        // Questions array
        questions: Question[];
    };

    export type Question = {
        pauseAt: number,
        questionString: string,
        answers: Answer[];
    };

    export type Answer = {
        answerString: string,
        correct: boolean,
        icon: AnswerDirectionIcon | string;
    };

    // Eva Icon names
    export enum AnswerDirectionIcon {
        None = "",
        Up = "arrow-up",
        Down = "arrow-down",
        Left = "arrow-left",
        Right = "arrow-right"
    }

    export type ResultsViewGroup = {
        name: string,
        columns: ResultsViewColumn[],
        rows: ResultsViewRow[]
    }
    export type ResultsViewColumn = {
        name: string,
        val: string
    }
    export type ResultsViewRow = {
        name: string,
        val: string
    }

    export type LimitedExercise = {
        [P in Exclude<(keyof Exercise), "questions">]: Exercise[P]
    } &  { publicVideoRef?:string };

    export var LimitedQuestionBank: LimitedExercise[] =
        [
            {
                "n": 4,
                "name": "GWTW2",
                "s3Key": "GWTWp2-05.mp4"
            },
            {
                "n": 5,
                "name": "VFTB",
                "s3Key": "Vftb-video.mp4"
            },
            {
                "n": 7,
                "name": "Heider-Simmel",
                "s3Key": "H-S-04.mp4",
                "publicVideoRef": "https://ibgpublicontent.s3.amazonaws.com/H-S-04.mp4"
            },
            {
                "n": 8,
                "name": "GWTW1",
                "s3Key": "03-GonePart1-revised.mp4"
            },
            {
                "n": 9,
                "name": "Mag7",
                "s3Key": "Magnificent-12.mp4"
            },
            {
                "n": 10,
                "name": "BigChill",
                "s3Key": "Bigchill-video.mp4"
            },
            {
                "n": 11,
                "name": "CrazyStupidLove CLS2",
                "s3Key": "Crazy-p2-video.mp4"
            },
            {
                "n": 12,
                "name": "Footloose",
                "s3Key": "Footloose-video.mp4"
            },
            {
                "n": 13,
                "name": "StreetCar",
                "s3Key": "Streetcar-video.mp4"
            },
            {
                "n": 14,
                "name": "American Hustle",
                "s3Key": "American-rev2.mp4"
            },
            {
                "n": 15,
                "name": "Philadelphia Story",
                "s3Key": "Phila-rev3.mp4"
            },
            {
                "n": 16,
                "name": "Ray",
                "s3Key": "Ray-rev2.mp4"
            },
            {
                "n": 17,
                "name": "Rescue",
                "s3Key": "Rescue-rev2.mp4"
            }
        ];

}


