import { AuthTypes } from "../Auth";
import { Validation } from './Validation';

export function auth_signupuser(u: AuthTypes.SignUpUser): Validation.Validations {
    
    let pwPolicy = {
        length: 8,
        uppercase: true,
        lowercase: true,
        number: true,
    }
    let pwMessage = "Password must be at least 8 characters long, contain a number, and have lowercase and uppercase characters."
    return [
        {
            condition: (u.email == undefined || typeof u.email != "string" || u.email == ""),
            error: { objectPath: ['email'], message: "Please enter an email." }
        },
        {
            condition: (u.name == undefined || typeof u.name != "string" || u.name == ""),
            error: { objectPath: ['name'], message: "Please enter a name." }
        },
        {
            condition: (u.password != u.confirmPassword),
            error: { objectPath: ['password'], message: "Passwords do not match." }
        },
        {
            condition: (u.password.length < 8),
            error: { objectPath: ['password'], message: pwMessage }
        },
        {
            condition: !(/\d/.test(u.password)),
            error: { objectPath: ['password'], message: pwMessage }
        },
        {
            condition: !(/[a-z]/.test(u.password)),
            error: { objectPath: ['password'], message: pwMessage }
        },
        {
            condition: !(/[A-Z]/.test(u.password)),
            error: { objectPath: ['password'], message: pwMessage }
        }


    ];
}