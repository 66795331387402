import { Component, OnInit } from '@angular/core';
import { UTIL } from 'src-shared/Utilities';
import { CommsService } from 'src/comms/comms.service';
import { UsersRequest } from 'src-shared/CommsRequests';
import { ConsolepopupService } from '../../popup/consolepopup.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

    loadAmountPerRequest = 30;
    users: UserRow[] = [];
    pToken?: string;
    usersLoading = false;
    searchKey = "";
    
    async searchClick() {
        this.usersLoading = true;
        let res = await this.comms.request({
            id: "users_searchUsers",
            payload: {
                emailPrefix: this.searchKey
            }
        });
        if (!res.payload) { console.log('Error fetching users.', res); return;}
        this.users = [];
        for (let u of res.payload.Users) {
            this.users.push(this.convert(u));
        }
        this.pToken = res.payload.PaginationToken;
        this.usersLoading = false;
    }
    searchClear() {
        this.searchKey = "";
        this.loadMore(true);
    }

    async actionsClick(u:UserRow) {
        if (!u.roles) {
            await this.fetchRoles(u);
        }
        this.popup.openUserActions({
            user: u
        });
    }
    
    async fetchRoles(u:UserRow) {
        let gsreq = await this.comms.request({
            id: "users_getGroupsForUser",
            payload: {
                id: u.id
            }
        });
        let groups: UsersRequest.AWSGroup[] = gsreq.payload;
        for (let ur of this.users) {
            if (ur.id == u.id) {
                ur.roles = [];
                for (let g of groups) {
                    ur.roles.push({
                        name: g.GroupName,
                        color: this.getStringColor(g.GroupName)
                    });
                }
            }
        }
    }

    async loadMore(first?:boolean) {
        this.usersLoading = true;
        let res = await this.comms.request({
            id: "users_getUsers",
            payload: {
                n: this.loadAmountPerRequest,
                paginationToken: this.pToken
            }
        });
        if (!res.payload) { console.log('Error fetching users.', res); return;}
        if (first) { this.users = []; }
        for (let u of res.payload.Users) {
            this.users.push(this.convert(u));
        }
        this.pToken = res.payload.PaginationToken;
        this.usersLoading = false;
    }

    getStringColor(s) {
        return '#' + UTIL.stringToRGB(s);
    }

    convert(u:UsersRequest.AWSCognitoUser): UserRow {
        let r : UserRow = {
            email: UTIL.getFirstKeyValueMatch(u.Attributes, "Name", <UsersRequest.AWSCognitoUserAttributeName>"email").Value,
            name: UTIL.getFirstKeyValueMatch(u.Attributes, "Name", <UsersRequest.AWSCognitoUserAttributeName>"custom:name")?.Value,
            joined: (new Date(u.UserCreateDate)).toLocaleString(),
            emailVerified: UTIL.getFirstKeyValueMatch(u.Attributes, "Name", <UsersRequest.AWSCognitoUserAttributeName>"email_verified")?.Value ,
            id: UTIL.getFirstKeyValueMatch(u.Attributes, "Name", <UsersRequest.AWSCognitoUserAttributeName>"sub").Value,
            user: u
        }

        return r;
    }
    
    async configure() {
        await this.loadMore(true);
    }

    constructor(private comms: CommsService, private popup: ConsolepopupService) { }

    ngOnInit(): void {
        this.configure();
    }

}

export type UserRow = {
    email: string,
    name?: string,
    roles?: UserRowRole[],
    joined: string,
    emailVerified?: string,
    id: string,
    user: UsersRequest.AWSCognitoUser
}
export type UserRowRole = {
    color: string,
    name: string
}