<div [className]="'navCtr' + (isMobile ? ' mobile' : '')" (window:resize)="onResize($event)">
    <div class="container">
        <div class="logo">
            <a href="https://therethinkgroup.net/">
                <img src="https://therethinkgroup.net/wp-content/uploads/2020/01/cropped-RethinkGroup_72dpi.png" class="logo-image" alt="The ReThink Group" />
            </a>
        </div>

        <div class="rightItems" *ngIf="!isMobile && metaheaderEnable">
            
            <div class="actions">
                <div class="action" *ngFor="let item of header.items">
                    <button class="actionButton" (click)="goto(item.link)">{{item.title}}</button>
                    <div class="subactions">
                        <div class="subaction" *ngFor="let subitem of item.subMenus">
                            <button class="subactionButton" (click)="goto(subitem.link)">{{subitem.title}}</button>
                        </div>
                    </div>
                </div>
                <div class="socialbuttons">
                    <div class="twitter">
                        <button (click)="twitterClick()">
                            <nb-icon icon="twitter"></nb-icon>
                        </button>
                    </div>
                    <div class="linkedin">
                        <button (click)="linkedinClick()">
                            <nb-icon icon="linkedin"></nb-icon>
                        </button>
                    </div>
                </div>
            </div>
            

        </div>
        <div class="rightItems" *ngIf="isMobile">=
            <a href="https://therethinkgroup.net/"><nb-icon icon="menu-outline" style="width: 30px; height: 30px; color: black; margin-top: 22px;"></nb-icon></a>
        </div>
    </div>
</div>