<nb-layout>
    <nb-layout-header>
        <app-navbar></app-navbar>
    </nb-layout-header>
    <nb-layout-column>
        <div class="innerContent">
            <h1>
                <b>Intuition Brain Game:</b> Log In
            </h1>
            <div class="main">
                <button nbButton ghost status="primary" class="goHomeButton" (click)="goHomeClick()"><nb-icon icon="arrow-back-outline"></nb-icon>Back To Home</button>
                <nb-card [nbSpinner]="loading" nbSpinnerStatus="primary">
                    <nb-card-body>
                        <div class="errorBar" *ngIf="invalid">
                            <nb-icon icon="alert-circle-outline"></nb-icon>{{invalidMessage}}
                            <br style="clear: both"/>
                        </div>
                        <b>Email</b>
                        <input nbInput fullWidth type="email" autocomplete="email" [(ngModel)]="email" (keyup)="inputKeyup($event)">
                        <br/><br/>
                        <b>Password</b>
                        <input nbInput fullWidth type="password"  autocomplete="current-password" [(ngModel)]="password" (keyup)="inputKeyup($event)">
                        <a class="forgot" (click)="forgotPWClick()">Forgot Password</a>
                        <br/><br/>
                        <button nbButton status="primary" class="submit" fullWidth (click)="signInClick()">Continue</button>
                    </nb-card-body>
                </nb-card>
                <!--<div class="tip">-->
                <!--    <b>Don't have an account?</b>-->
                <!--    <br style="clear: both;"/>-->
                <!--    <button nbButton outline status="primary" class="nullBtn" fullWidth (click)="signUpClick()">Subscribe</button>-->
                <!--    <br style="clear: both;"/><br/>-->
                <!--    <b>Bookmark this page for quick access.</b>-->
                <!--</div>-->
            </div>
        </div>
        
    </nb-layout-column>
</nb-layout>