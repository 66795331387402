<h1>Site Settings</h1>
<div class="contentDiv">
    <nb-card>
        <nb-card-body>
            <div class="settingsAll">
                <div class="setting" *ngFor="let s of settings">

                    <div>
                        <div class="leftArea">
                            <div><b>{{s.name}}</b></div>
                            <div><p>{{s.description}}</p></div>
                        </div>
                        <div class="rightArea">
                            <div class="editBox">
                                <input *ngIf="s.setting == 'text'" nbInput fullWidth [(ngModel)]="s.value">

                                <div *ngIf="s.setting == 'options'">
                                    <nb-select [(ngModel)]="s.value" fullWidth>
                                        <nb-option *ngFor="let op of $any(s).options" [value]="op.id">{{op.name}}</nb-option>
                                    </nb-select>
                                </div>
            
                                <div *ngIf="s.setting == 'textboxes'">
            
                                </div>

                            </div>
                            <button *ngIf="s.setting != 'action'" nbButton class="saveBtn" shape="round" outline status="primary" (click)="saveBtn(s)" [nbSpinner]="s.isLoading" [nbSpinnerStatus]="'primary'">Save</button>
                            <button *ngIf="s.setting == 'action'" nbButton class="saveBtn actionSave" shape="round" outline status="primary" (click)="actionClick(s)" [nbSpinner]="s.isLoading" [nbSpinnerStatus]="'primary'">{{s.name}}</button>
                        </div>
                    </div>

                    
                    <br style="clear:both;"/>
                </div>
            </div>
        </nb-card-body>
    </nb-card>
</div>