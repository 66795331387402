<nb-card class="subCard" *ngIf="package">
    <nb-card-header>
        {{package.name}}
    </nb-card-header>
    <nb-card-body>
        <nb-tabset class="detailsTabset" fullWidth='false'>
            <nb-tab class="t1" tabTitle="Billing Plans">
                
                <ng-container *ngFor="let plan of package.plans">
                    <app-subscriptions-plan *ngIf="!plan.deactivated" [plan]="plan" [package]="package"></app-subscriptions-plan>
                </ng-container>
                

                <div class="noPlansTitle" *ngIf="package.plans.length < 1"><i>No active plans for this subscription.</i></div>
                

                <nb-card class="editPlanCard" *ngIf="showPlanEditor" [nbSpinner]="editorIsLoading" nbSpinnerStatus="primary">
                    <nb-card-header>
                        New Billing Plan
                    </nb-card-header>
                    <div class="warning">
                        <nb-icon icon="alert-circle-outline"></nb-icon>
                        You won't be able to edit details for this plan later.
                    </div>
            
                    <div class="editRow">
                        <div class="detail">
                            <div class="detailName">SET PLAN NAME</div><br />
                            <input class="planEditInput" nbInput placeholder="ex. Music Monthly Subscription" style="width: 320px;" [(ngModel)]="editingPlan.name">
                        </div>
                        <div class="infoRight">
                            <nb-icon icon="info-outline"></nb-icon>
                            <p>This name will be visible to users during checkout and in associated notifications and settings.</p>
                        </div>
                    </div>
            
                    <div class="editRow">
                        <div class="detail">
                            <div class="detailName">BILL PRICE</div><br />
                            <input class="planEditInput" nbInput placeholder="$" style="height: 32px; width: 80px;" [(ngModel)]="editingPlan.billPrice" (focusout)="billPriceEdit()">
                        </div>
                        <div class="detail">
                            <div class="detailName">BILL FREQUENCY</div><br />
                            <nb-select size="small" style="width: 120px;" [(ngModel)]="editingPlan.billPeriodDurationInDays" (selectedChange)="totalEdit()">
                                <nb-option *ngFor="let p of getKeys(subRef.DefaultBillingPeriods)" [value]="parseInt(p)">{{subRef.DefaultBillingPeriods[p]}}</nb-option>
                            </nb-select>
                        </div>
                        <div class="detail">
                            <div class="detailName">ANNUAL TOTAL</div><br />
                            <input class="planEditInput" nbInput placeholder="$" style="height: 32px; width: 100px;" (focusout)="totalEdit()" [(ngModel)]="totalValue">
                        </div>
                        <div class="infoRight">
                            <nb-icon icon="info-outline"></nb-icon>
                            <p>Billing periods are always equal fixed time-lengths apart.
                                <li>Daily: 24 Hours</li>
                                <li>Weekly: 7 Days</li>
                                <li>Bi-Weekly: 14 Days</li>
                                <li>Monthly: 30 Days</li>
                                <li>Quarterly: 89 Days</li>
                                <li>Anually: 365 Days</li>
                            </p>
                        </div>
            
                        <br style="clear: left;"/>
                        <br/>
                        <div class="detail">
                            <div class="detailName">CURRENCY</div><br />
                            <nb-select size="small" style="width: 100px;" [(ngModel)]="editingPlan.currencyCode">
                                <nb-option *ngFor="let c of getKeys(subRef.CurrencyList)" [value]="c">{{c}}</nb-option>
                            </nb-select>
                        </div>
                        
                    </div>
                    <div class="editRow">
                        <div class="detail">
                            <div class="detailName">TRIAL PERIOD DURATION</div><br />
                            <input class="planEditInput" nbInput placeholder="" style="height: 32px; width: 80px;" [(ngModel)]="editingPlan.trialPeriodDurationInDays">
                        </div>
                        <div class="infoRight">
                            <nb-icon icon="info-outline"></nb-icon>
                            <p>Duration is in days.
                                <li>Set trial period duration to zero for no trial.</li>
                            </p>
                        </div>
                        <br style="clear: both;"/>
                        <br/>
                    </div>
            
                    <div class="editRow">
                        <div class="detail">
                            <div class="detailName">IF USER UNSUBSCRIBES</div><br />
                            <nb-select size="small" style="width: 320px;" [(ngModel)]="editingPlan.onUnsubscribe">
                                <nb-option *ngFor="let p of getKeys(subRef.OnUnsubscribePolicyList)" [value]="p" [disabled]="p != 'immediate_unenroll'">{{subRef.OnUnsubscribePolicyList[p].description}}</nb-option>
                            </nb-select>
                        </div>
                        <div class="infoRight">
                            <nb-icon icon="info-outline"></nb-icon>
                            <p>This selection will be visible to users during checkout and in associated notifications and settings.
                                <li>Immediately unenroll: User roles are edited immediately.</li>
                                <li>Delayed unenroll: User roles are edited at the start of the next billing period. <i>(Currently Unavailable)</i></li>
                            </p>
                        </div>
                    </div>
                    <div class="editRow">
                        <div class="detail">
                            <div class="detailName">INITIAL STATUS</div><br />
                            <nb-select size="small" style="width: 320px;" [(ngModel)]="editorStatus">
                                <nb-option *ngFor="let s of getKeys(subRef.SubscriptionPlanStatusList)" [value]="s">{{subRef.SubscriptionPlanStatusList[s].description}}</nb-option>
                            </nb-select>
                        </div>
                        <div class="infoRight">
                            <nb-icon icon="info-outline"></nb-icon>
                            <p>When enrollment is paused, this billing plan wont be visible to users during checkout. Enrollment can be resumed at any time.</p>
                        </div>
                    </div>
                    <div class="bottomActionRow">
                        <div class="dButton">
                            <button nbButton status="basic" (click)="cancelPlanClick()">CANCEL</button>
                            <button nbButton status="success" (click)="deployPlanClick()">DEPLOY PLAN</button>
                        </div>
                    </div>
                </nb-card>


                <button class="addButton" (click)="addPlanClick()">
                    <nb-icon icon="plus-circle"></nb-icon>
                    <br style="clear: both;"/>
                    <b>ADD BILLING PLAN</b>
                </button>
    
            </nb-tab>
            <nb-tab tabTitle="Details" class="detailsTab">
                <div>
                    <b>STATIC ID</b>
                    <div>{{package.staticID}}</div>
                </div>
                <br/>
                <div>
                    <b>ACCESS ROLE</b>
                    <div>{{package.accessRole}}</div>
                </div>
            </nb-tab>
        </nb-tabset>
    </nb-card-body>

    <nb-card-footer>
        <button nbButton status="danger" (click)="deletePackageClick()">DELETE PACKAGE</button>
        <button nbButton status="basic" *ngIf="false" class="subEditButton">EDIT PACKAGE DETAILS</button>
    </nb-card-footer>
    
</nb-card>