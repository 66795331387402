import { Exercises } from './Exercise';

export namespace Rethink {
    export type Header = {
        items: HeaderItem[]
    }
    export type HeaderItem = {
        title:string,
        link:string,
        subMenus:{
            title:string,
            link:string
        }[]
    }
    export type CompleteExercise = {
        isSignedIn: boolean, 
        isSubscribed: boolean,
        videoRef: string, 
        exercise: Exercises.Exercise
    }
    export type PromisedCompleteExercise = {
        [P in keyof CompleteExercise]:Promise<CompleteExercise[P]>
    }

    export var SubscriberUserGroupName = "ibgsubscriber" ;
}