<app-popup-frame [actions]="actions" [header]="'Upload New Payment Credentials'" status='danger' (action)="action($event)" [loading]="cardLoading">
    <div [className]="'uploadPayment'">
        <div>
            Upload a new set of PayPal REST API Credentials from your PayPal Business account to use with the Payments app.
        </div>
        <br />
        <div class="cred">
            <b>New Client ID</b>
            <br />
            {{newID}}
            <br />
            <b>New Secret</b>
            <br />
            
            <i>{{newSecret}}</i>
        </div>
        <br />
        <nb-card class="verCard" [nbSpinner]="verLoading" [nbSpinnerStatus]="'primary'" [nbSpinnerMessage]="'Verifying Credentials'">
            <div *ngIf="!verLoading">
                <div *ngIf="verPass">
                    <div class="sCard">
                        <nb-icon icon="checkmark"></nb-icon>Credentials Verified
                        <br style="clear:both" />
                    </div>
                    <div class="msg">Authorization was successfully recieved using these credentials from PayPal</div>
                </div>
                <div *ngIf="!verPass">
                    <div class="fCard">
                        <nb-icon icon="close"></nb-icon>Credential Verification Failed
                        <br style="clear:both" />
                    </div>
                    <div class="msg">Authorization with PayPal was unsuccessful, recheck keys.</div>
                </div>
            </div>

        </nb-card>
        <b>Are you sure you want to upload these new credentials?</b>
        <br />
        <br />
        <b>Your previous credentials will be erased.</b>
    </div>

</app-popup-frame>