import { Component, OnInit } from '@angular/core';
import { CommsService } from 'src/comms/comms.service';
import { Redirect } from 'src/Redirect';

@Component({
    selector: 'app-upload-payment-creds-popup',
    templateUrl: './upload-payment-creds-popup.component.html',
    styleUrls: ['./upload-payment-creds-popup.component.scss']
})
export class UploadPaymentCredsPopupComponent implements OnInit {

    newID: string;
    newSecret: string;
    actions = [
        {
            id: "upload",
            text: "Upload",
            status: "danger"
        },
        {
            id: "cancel",
            text: "Cancel",
            status: "basic",
            isCloseButton: true
        }
    ];

    verLoading = true;
    verPass = false;
    cardLoading = false;

    async action(id) {
        if (id == "upload") {
            this.cardLoading = true;
            let res = await this.comms.request({
                id: "payment_setAccountInfo",
                payload: {
                    clientID: this.newID,
                    secret: this.newSecret
                }
            });
            if (res.payload == "Success") {
                Redirect.reload();
            }
            this.cardLoading = false;
        }
    }

    async configure() {
        this.verLoading = true;
        this.verPass = false;
        let res = await this.comms.request({
            id: "payment_verify",
            payload: {
                clientID: this.newID,
                secret: this.newSecret
            }
        });
        if (res.payload == "Success") {
            this.verPass = true;
        } else {
            this.verPass = false;
        }
        this.verLoading = false;
    }

    constructor(private comms: CommsService) { }

    ngOnInit(): void {
        this.configure();
    }

}
