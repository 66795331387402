<nb-layout>
    <nb-layout-header>
        <app-navbar></app-navbar>
    </nb-layout-header>
    <nb-layout-column>
        <div class="columnContainer innerContent">
            <h1><b>Intuition Brain Game: </b>Subscribe</h1>

            <div [className]="'main'" *ngIf="!isSignedIn">
                <div>
                    <div class="flexContainer">
                        <div class="cardContainer">
                            <nb-card [nbSpinner]="isLoading" nbSpinnerStatus="primary">
                                <nb-card-body>
                                    <div class="errorBar" *ngIf="invalid">
                                        <nb-icon icon="alert-circle-outline"></nb-icon>{{invalidMessage}}
                                        <br style="clear: both" />
                                    </div>

                                    <b>Email*</b>
                                    <input nbInput fullWidth type="email" autocomplete="email" [(ngModel)]="email">
                                    <br />
                                    <b>Name*</b>
                                    <input nbInput fullWidth [(ngModel)]="name">
                                    <br />
                                    <div class="multipleInput">
                                        <div class="inL">
                                            <b>Company</b>
                                            <input nbInput fullWidth [(ngModel)]="company">
                                        </div>
                                        <div class="inR">
                                            <b>Job Title</b>
                                            <input nbInput fullWidth [(ngModel)]="jobTitle">
                                        </div>
                                        <br style="clear: both" />
                                    </div>
                                    <div class="multipleInput">
                                        <div class="inL">
                                            <b>Password*</b>
                                            <input nbInput fullWidth id="pw" type="password" autocomplete="new-password" [(ngModel)]="password">
                                        </div>
                                        <div class="inR">
                                            <b>Confirm Password*</b>
                                            <input nbInput fullWidth id="confirmPW" type="password" autocomplete="new-password" [(ngModel)]="confirmPassword">
                                        </div>
                                        <br style="clear: both" />
                                    </div>
                                    <div class="checkboxes">
                                        <b>Occupation</b>
                                        <div class="cbbox">
                                            <nb-checkbox *ngFor="let b of marketsTraded" [(ngModel)]="b.value">{{b.name}}</nb-checkbox>
                                            <br style="clear:both" />
                                        </div>
                                    </div>
                                    <b>* Required</b>
                                    <br /><br />
                                    <button nbButton status="primary" class="submit" fullWidth (click)="createClick()">Continue To Payment</button>
                                </nb-card-body>
                            </nb-card>
                        </div>

                        <div class="sideInfo">
                            <div class="desc" style="text-align: center;">
                                <b>Let’s Get Started!</b>
                                <p>
                                    Subscribe today for $8.99/month ($89/year) to play unique brain games to test, train and hone your intuitive skills.
                                </p>
                            </div>
                            <br />
                            <div class="tip">
                                <b>Already have an account?</b>
                                <br style="clear: both;" />
                                <button class="loginBtn" nbButton outline status="primary" fullWidth (click)="signInClick()" shape="round">Log In</button>
                                <br />
                                <button class="goHomeBtn" nbButton ghost status="primary" fullWidth (click)="goHomeClick()">Back To Home</button>
                            </div>
                        </div>
                    </div>
                    <br style="clear: both;" />

                </div>

            </div>

            <div class="main alreadyInView" *ngIf="isSignedIn">
                <div class="t"><b>Look's like you're already logged in.</b></div>
                <div class="desc">Log out first to subscribe with a new account, or <a [href]='managesubsLink'><b>Unsubscribe</b></a> to subscribe to a different plan.</div>
                <br />
                <button nbButton status="primary" outline (click)="logOutClick()">Log Out</button>
            </div>

        </div>
    </nb-layout-column>
    <nb-layout-footer></nb-layout-footer>
</nb-layout>