import { Injectable } from '@angular/core';
import { NbDialogService, NbDialogRef } from '@nebular/theme';
import { UploadPaymentCredsPopupComponent } from './popups/upload-payment-creds-popup/upload-payment-creds-popup.component';
import { DeletePaymentCredsPopupComponent } from './popups/delete-payment-creds-popup/delete-payment-creds-popup.component';
import { UserActionsPopupComponent } from './popups/user-actions-popup/user-actions-popup.component';
import { DeactivateplanwarnComponent } from './popups/deactivateplanwarn/deactivateplanwarn.component';
import { PlanactionswarnComponent } from './popups/planactionswarn/planactionswarn.component';

@Injectable({
    providedIn: 'root'
})
export class ConsolepopupService {

    currentRef: NbDialogRef<any>;

    openPayment_uploadCredentials(context: Partial<UploadPaymentCredsPopupComponent>) {
        this.currentRef = this.ds.open(UploadPaymentCredsPopupComponent, {
            context: context
        });
    }

    openPayment_deleteCredentials(context: Partial<DeletePaymentCredsPopupComponent>) {
        this.currentRef = this.ds.open(DeletePaymentCredsPopupComponent, {
            context: context
        });
    }

    openUserActions(context: Partial<UserActionsPopupComponent>) {
        this.currentRef = this.ds.open(UserActionsPopupComponent, {
            context: context
        });
    }

    openDeactivatePlanWarning(context: Partial<DeactivateplanwarnComponent>) {
        this.currentRef = this.ds.open(DeactivateplanwarnComponent, {
            context: context
        });
    }

    openPlanAction(context: Partial<PlanactionswarnComponent>) {
        this.currentRef = this.ds.open(PlanactionswarnComponent, {
            context: context            
        });
    }

    close() {
        this.currentRef.close();
    }

    constructor(private ds : NbDialogService) { }
}
